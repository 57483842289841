import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@charity/../environments/environment';
import { AppRoutingModule } from '@charity/app-routing.module';
import {
  CharityEffects,
  CoreModule,
  DonationEffects,
  ItemEffects,
  OnboardingEffects,
  PartnerEffects,
  reducerProvider,
  reducerToken,
  StoreEffects,
  UsersEffects,
} from '@core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RsplApiModule } from '@rspl-api';
import { RsplAuthModule } from '@rspl-auth';
import { RsplMapModule } from '@rspl-map';
import { RsplTableModule } from '@rspl-table';
import { RsplUIModule } from '@rspl-ui';
import { RsplVersionConfig, RsplVersionModule } from '@rspl-version';
import { AppComponent } from './app.component';

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        CoreModule.forRoot(environment),
        AppRoutingModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true
        }),
        EffectsModule.forRoot([
            DonationEffects,
            CharityEffects,
            PartnerEffects,
            OnboardingEffects,
            StoreEffects,
            UsersEffects,
            ItemEffects,
        ]),
        StoreModule.forRoot(reducerToken),
        RsplUIModule.forRoot(),
        RsplMapModule.forRoot(),
        RsplVersionModule.forRoot({
            currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
        } as RsplVersionConfig),
        RsplTableModule.forRoot(),
        RsplApiModule.forRoot(),
        RsplAuthModule.forRoot()], providers: [reducerProvider, { provide: MAT_DATE_LOCALE, useValue: 'en' }, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule {}
