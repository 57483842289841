import { Anomaly, FindParams } from '@domains';
import { createAction, props } from '@ngrx/store';

export enum AnomalyActionType {
  ClearAnomaliesFindParams = '[Charity] Clear Anomalies Find Params',
  GetAnomalies = '[Anomaly] Get Anomalies',
  GetAnomaliesSuccess = '[Anomaly] Get Anomalies Success',
}

export const clearAnomaliesFindParams = createAction(
  AnomalyActionType.ClearAnomaliesFindParams
);
export const getAnomalies = createAction(
  AnomalyActionType.GetAnomalies,
  props<{ findParams: FindParams; keep?: boolean }>()
);
export const getAnomaliesSuccess = createAction(
  AnomalyActionType.GetAnomaliesSuccess,
  props<{ anomalies: Array<Anomaly>; resultsCount: number }>()
);
