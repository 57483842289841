import { AvailabilityDto } from "./availability-dto";

export interface WeekAvailabilityDto {
  available: AvailabilityDto[];
  in_store_zone: boolean;
  in_vehicle_zone: boolean;
  vehicles: AvailabilityDto[];
  stores: AvailabilityDto[];
  week?: number;
  year?: number;
}
