import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { DomSanitizer, Title } from '@angular/platform-browser';
import {
  NavigationEnd,
  NavigationStart,
  Params,
  Router,
} from '@angular/router';
import {
  clearUser,
  getCharity,
  getPartner,
  selectCharity,
  selectPartner,
  selectPendingDonationsCount,
  setPendingDonationsCount,
} from '@core';
import {
  AppType,
  AuthenticationModel,
  BuildType,
  Charity,
  Design,
  DonationPartnerState,
  ENVIRONMENT,
  Environment,
  PageAction,
  Partner,
  Role,
  Theme,
  User,
} from '@domains';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { DonationsService, LocalStorageService, UsersService } from '@rspl-api';
import { AuthenticationService } from '@rspl-auth';
import { TableService } from '@rspl-table';
import {
  BackService,
  DesignService,
  Responsive,
  ResponsiveService,
  ThemeService,
} from '@rspl-ui';
import * as moment from 'moment';
import { filter, take, takeUntil } from 'rxjs/operators';
import { AppState } from '../../store/app-state.interface';
import { setPageHeader } from '../../store/page/page.actions';
import {
  DEFAULT_HEADER_POSITION,
  PageHeaderConfig,
} from './page-header-config';

@Component({
  selector: 'app-ui-template',
  templateUrl: './ui-template.component.html',
  styleUrls: ['./ui-template.component.scss'],
})
export class UiTemplateComponent
  extends Responsive
  implements OnInit, OnDestroy
{
  @ViewChild('drawer', { static: true }) drawer: MatSidenav;
  @ViewChild('sidenavContainer', { static: true })
  sidenavContainer: MatSidenavContainer;

  pageHeaderDivTimeout;
  private openedPopup = false;
  theme: Theme = Theme.LIGHT;
  autoTheme: boolean;
  app: AppType;
  isStaging: boolean;
  isDev: boolean;
  isTest: boolean;
  isDemo: boolean;
  buildType: BuildType;
  zendesk: string;
  pendingCount = 0;

  #pageHeaderDiv: ElementRef;
  @ViewChild('pageHeaderDiv') set pageHeaderDiv(pageHeaderDiv: ElementRef) {
    this.#pageHeaderDiv = pageHeaderDiv;
    this.setHeaderHeight();
  }

  get pageHeaderDiv(): ElementRef {
    return this.#pageHeaderDiv;
  }

  pageHeaderPosition: PageHeaderConfig;

  pageTitle: string;

  onboardingCompleted: boolean;

  partnerId: string;
  partner: Partner;

  charityId: string;
  charity: Charity;

  auth?: AuthenticationModel;
  editMode: boolean;
  appType;
  rootPath;
  isCaptain: boolean;
  isCharity: boolean;
  isTpl: boolean;
  isZendesk: boolean;
  isStore = false;
  pageHeader: {
    hasBackButton?: boolean;
    pageTitle?: string;
    subtitle?: string;
    actions?: PageAction[];
    back?: string;
    queryParams?: Params;
  };
  headerHeight = 52;
  themes = Theme;

  zoom = 1;

  stickyMenu;
  #menuExpanded = false;

  set menuExpanded(menuExpanded: boolean) {
    this.#menuExpanded = menuExpanded;
    this.openChanged();
  }

  public get menuExpanded(): boolean {
    return this.#menuExpanded;
  }

  public constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private store: Store<AppState>,
    public router: Router,
    private titleService: Title,
    public override responsiveService: ResponsiveService,
    public backService: BackService,
    private actions: Actions,
    private themeService: ThemeService,
    private authService: AuthenticationService,
    private tableService: TableService,
    private localStorage: LocalStorageService,
    private designService: DesignService,
    private userService: UsersService,
    private donationService: DonationsService,
    @Inject(ENVIRONMENT) protected environment: Environment
  ) {
    super(responsiveService);
    this.stickyMenu = this.localStorage.getItem('rspl-sticky-menu') === 'true';
    this.app = environment.app;
    this.isStaging = environment.isStagingBuild;
    this.isDev = environment.isDevBuild;
    this.isTest = environment.isTestBuild;
    this.isDemo = environment.isDemoBuild;
    this.buildType = environment.buildType;
    this.zendesk = environment.urls.zendesk;
    this.appType = environment.app;
    this.rootPath = environment.loginRedirectionRoute;
    this.isCaptain = environment.app === AppType.CAPTAIN;
    this.isCharity = environment.app === AppType.CHARITY;
    this.isTpl = environment.app === AppType.TPL;
    this.isZendesk = environment.app === AppType.ZENDESK;
    if (this.isCaptain || this.isZendesk) {
      this.pageTitle = 'Resupply Captain Portal';
    } else if (this.isTpl) {
      this.pageTitle = 'Resupply Partner Portal';
    } else {
      this.pageTitle = 'Resupply Charity Portal';
    }
    iconRegistry.addSvgIcon(
      'parachute-icon',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/parachute-box-light.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'charities-icon',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/warehouse-alt-light.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'users-icon',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/user-friends-light.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'truck-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/truck-light.svg')
    );
    iconRegistry.addSvgIcon(
      'web-v3-truck-icon',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/web-v3-truck-icon.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'user-icon',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/user-solo-stroke.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'square-dolar',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/square-dolar.svg')
    );
    iconRegistry.addSvgIcon(
      'rspl-new-logo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/new_logo.svg')
    );
    iconRegistry.addSvgIcon(
      'facebook',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/facebook.svg')
    );
    iconRegistry.addSvgIcon(
      'instagram',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/instagram.svg')
    );
    iconRegistry.addSvgIcon(
      'linkedin',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/linkedin.svg')
    );
    iconRegistry.addSvgIcon(
      'box',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/box-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'stairs',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/stairs-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'wrench',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/wrench-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'truck',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/truck-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'glass',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/glass-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'prioritystar',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/prioritystar-icon.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'header-logo',
      sanitizer.bypassSecurityTrustResourceUrl(
        this.isCharity
          ? 'assets/images/new_logo_short.svg'
          : 'assets/images/new_logo_short_white.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'warning',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/warning-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'ss-primary-disassembly',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/split-screen/primary-disassembly.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'ss-primary-floor',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/split-screen/primary-floor.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'ss-primary-items',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/split-screen/primary-items.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'ss-primary-time',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/split-screen/primary-time.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'ss-standard-disassembly',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/split-screen/standard-disassembly.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'ss-standard-floor',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/split-screen/standard-floor.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'ss-standard-items',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/split-screen/standard-items.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'ss-standard-time',
      sanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/split-screen/standard-time.svg'
      )
    );
    iconRegistry.addSvgIcon(
      'away',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/away.svg')
    );
    iconRegistry.addSvgIcon(
      'theme-light',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/theme-light.svg')
    );
    iconRegistry.addSvgIcon(
      'theme-dark',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/theme-dark.svg')
    );
    iconRegistry.addSvgIcon(
      'theme-auto',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/theme-auto.svg')
    );
    iconRegistry.addSvgIcon(
        'away',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/away.svg')
    );
    iconRegistry.addSvgIcon(
        'zendesk',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/zendesk.svg')
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.designService.setDesign(Design.DESIGN_1);
    this.setOnboarding();
    this.titleService.setTitle(this.pageTitle);
    this.router.events.subscribe((event) => {
      this.setOnboarding();
      this.closeSideNav();
    });
    document.getElementById('app-page-loader')?.remove();
    this.authService.authData$
      .pipe(filter((res) => !!res), takeUntil(this.destroy$))
      .subscribe((auth) => {
        this.auth = auth;
        if (this.auth) {
          this.store
            .pipe(select(state => state.user.user), filter(user => !!user), take(1))
            .subscribe((user) => {
              if (
                (this.isTpl && this.auth.partnerId !== user.partnerId) ||
                (this.isCharity && this.auth.charityId !== user.charityId)
              ) {
                this.logout();
                return;
              }
              if (
                this.isTpl &&
                user?.partnerId &&
                (!this.partnerId || this.partnerId !== user.partnerId)
              ) {
                this.partnerId = user.partnerId;
                this.store.dispatch(getPartner({ id: user.partnerId }));
                this.donationService
                  .filter({
                    page: 1,
                    per_page: 1,
                    order: 'date',
                    order_direction: 'asc',
                    date_after: moment().format('YYYY-MM-DD'),
                    partner_state: DonationPartnerState.assigned,
                  })
                  .pipe(take(1))
                  .subscribe((res) => {
                    this.store.dispatch(
                      setPendingDonationsCount({ count: res.totalResults })
                    );
                  });
              }
              if (
                this.isCharity &&
                user?.charityId &&
                (!this.charityId || this.charityId !== user.charityId)
              ) {
                this.charityId = user.charityId;
                this.store.dispatch(getCharity({ id: user.charityId }));
              }
              if (this.auth?.authorization) {
                this.isCaptain = this.auth.authorization.roles.includes(
                  Role.CAPTAIN
                );
                this.isCharity =
                  this.auth.authorization.roles.includes(Role.CHARITY) ||
                  this.auth.authorization.roles.includes(Role.STORE);
                this.isTpl = this.auth.authorization.roles.includes(
                  Role.PARTNER
                );
                this.isStore = this.auth.authorization.roles.includes(
                  Role.STORE
                );
              }
              if (
                user?.partnerId &&
                (!this.partnerId || this.partnerId !== user.partnerId)
              ) {
                this.partnerId = user.partnerId;
                this.store.dispatch(getPartner({ id: user.partnerId }));
              }
            });
        }
      });
    this.tableService.editMode$
      .pipe(takeUntil(this.destroy$))
      .subscribe((editMode) => {
        this.editMode = editMode;
      });
    this.drawer.openedChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (opened) => (document.body.style.overflow = opened ? 'hidden' : 'auto')
      );

    this.router.events
      .pipe(
        filter((x) => x instanceof NavigationStart),
        takeUntil(this.destroy$)
      )
      .subscribe(() => (this.pageHeader = undefined));
    this.actions
      .pipe(ofType(setPageHeader), takeUntil(this.destroy$))
      .subscribe((pageHeader) => {
        this.pageHeader = pageHeader;
        this.titleService.setTitle(
          this.pageTitle + ' - ' + this.pageHeader.pageTitle
        );
        this.setHeaderHeight();
        this.onScroll();
      });

    this.router.events
      .pipe(filter((x) => x instanceof NavigationEnd))
      .subscribe((event) => {
        document.documentElement.scrollTop = 0;
      });

    if (this.environment.app !== AppType.TAX_RECEIPT) {
      this.themeService.theme
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.theme = data.theme || Theme.LIGHT;
          this.autoTheme = !!data.auto;
        });
    }

    if (this.isTpl) {
      this.store
        .pipe(select(selectPartner), takeUntil(this.destroy$))
        .subscribe((p) => (this.partner = p));
      this.store
        .pipe(select(selectPendingDonationsCount), takeUntil(this.destroy$))
        .subscribe((pendingCount) => (this.pendingCount = pendingCount));
    }
    if (this.isCharity) {
      this.store
        .pipe(select(selectCharity), takeUntil(this.destroy$))
        .subscribe((c) => (this.charity = c));
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public closeSideNav(): void {
    this.drawer.close();
  }

  public logout(): void {
    this.authService.logout();
    this.store.dispatch(clearUser());
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent): void {
    const w = event.target as Window;
    this.responsiveService.setWindowSize(w.innerWidth);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    const skip = 20;
    const max = 100;

    const isMax =
      window.scrollY - skip > max * 3 &&
      (this.pageHeaderPosition?.scrollPercent || 1) >= 1;
    const openedPopup =
      document.getElementsByClassName('mat-mdc-dialog-panel').length > 0;
    if (isMax || openedPopup || this.openedPopup) {
      this.openedPopup = openedPopup;
      return;
    }
    const scrollY = Math.max(window.scrollY - skip, 0);
    const scrollPercent = Math.min((scrollY / max) * 100, max) / 100;

    this.openedPopup = openedPopup;

    const reduceWidthBy = this.isSmallWindowSize
      ? (this.isZendesk ? 56 : 86) +
        10 +
        (this.isTpl || this.isCharity ? 86 : 56)
      : (this.isZendesk ? 56 : 136) +
        10 +
        (this.isTpl || this.isCharity ? 96 : 76);

    this.pageHeaderPosition =
      scrollY < 10
        ? this.defaultHeaderPosition()
        : {
            scrollPercent,
            toolbar: {
              reduceWidthBy: reduceWidthBy * scrollPercent,
              left:
                ((this.isSmallWindowSize
                  ? this.isZendesk
                    ? 50
                    : 80
                  : this.isZendesk
                  ? 50
                  : 116) +
                  10) *
                  scrollPercent +
                (this.isSmallWindowSize ||
                this.environment.isZendesk ||
                !this.stickyMenu
                  ? 0
                  : 65) *
                  (1 - scrollPercent),
              height:
                scrollPercent === 1 ? 52 : this.isSmallWindowSize ? 75 : 112,
            },
            back: {
              'border-color': scrollPercent > 0.65 ? 'transparent' : null,
              color: scrollPercent > 0.65 && !this.isCharity ? 'white' : null,
            },
            title: {
              width: this.isSmallWindowSize
                ? 'calc(100vw - 155px - ' +
                  (reduceWidthBy +
                    15 +
                    (this.pageHeader?.hasBackButton ? 40 : 0) +
                    (this.pageHeader?.actions?.length > 0 ? 30 : 0) -
                    155) *
                    scrollPercent +
                  'px)'
                : null,
              filter:
                'brightness(' +
                ((scrollPercent > 0.65 && !this.isCharity
                  ? scrollPercent * 1000
                  : 0) +
                  100) +
                '%)',
              'font-size': 100 + 20 * (1 - scrollPercent) + '%',
            },
            buttons: {
              scrolled: scrollPercent > 0.1,
              showIcon: scrollPercent > 0,
              iconOpacity: scrollPercent,
              txtOpacity: 1 - scrollPercent,
              'border-color': scrollPercent > 0.65 ? 'transparent' : null,
              'background-color':
                scrollPercent < 0.65
                  ? null
                  : 'rgba(255, 255, 255, ' +
                    (1 - scrollPercent) *
                      (this.theme === Theme.DARK ? 10 : 100) +
                    '%)',
              padding: '0 ' + (1 - scrollPercent) * 20 + 'px',
            },
          };
  }

  private defaultHeaderPosition(): PageHeaderConfig {
    this.setHeaderHeight();
    return {
      ...DEFAULT_HEADER_POSITION(
        this.isSmallWindowSize || this.environment.isZendesk || !this.stickyMenu
      ),
      toolbar: {
        ...DEFAULT_HEADER_POSITION(
          this.isSmallWindowSize ||
            this.environment.isZendesk ||
            !this.stickyMenu
        ).toolbar,
        height: this.isSmallWindowSize ? 75 : 112,
      },
    };
  }

  public updateZoomLevel(): void {
    if (this.zoom < 1.5) {
      this.zoom = this.zoom + 0.1;
    } else {
      this.zoom = 1;
    }
  }
  public updateZoomLevelUp(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.zoom < 1.5) {
      this.zoom = this.zoom + 0.1;
    }
  }

  public updateZoomLevelDown(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.zoom > 0.51) {
      this.zoom = this.zoom - 0.1;
    }
  }

  get zoomLevel(): number {
    return Math.round(this.zoom * 100);
  }

  setTheme(event: Event, theme?: Theme): void {
    event.preventDefault();
    event.stopPropagation();
    this.themeService.setTheme(theme);
  }

  private setOnboarding(): void {
    if (this.localStorage.getItem('rspl-user')) {
      const user = new User(
        JSON.parse(this.localStorage.getItem('rspl-user')),
        this.environment.app
      );
      this.onboardingCompleted = !user.meta || user.meta.onboardingCompleted;
    }
  }

  get visibleActionsCnt(): number {
    return this.pageHeader?.actions?.filter((x) => !x.hidden || !x.hidden())
      ?.length;
  }

  setHeaderHeight() {
    if (this.pageHeaderDivTimeout) {
      clearTimeout(this.pageHeaderDivTimeout);
    }
    this.pageHeaderDivTimeout = setTimeout(() => {
      this.headerHeight =
        52 +
        this.pageHeaderDiv?.nativeElement.offsetHeight +
        (this.isSmallWindowSize ? 20 : 0);
    });
  }

  backdropClick() {
    if (this.isSmallWindowSize || !this.stickyMenu) {
      this.drawer.close();
    } else {
      this.menuExpanded = false;
    }
  }

  openChanged() {
    setTimeout(() => {
      if (this.menuExpanded) {
        document.body.setAttribute('style', 'overflow: hidden');
      } else {
        document.body.setAttribute('style', 'overflow: auto');
      }
    });
  }

  setStickyMenu(event: Event, stickyMenu: boolean): void {
    event.preventDefault();
    event.stopPropagation();
    this.stickyMenu = stickyMenu;
    this.localStorage.setItem('rspl-sticky-menu', stickyMenu.toString());
    this.onScroll();
  }
}
