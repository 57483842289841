export interface FindParams {
  page?: number;
  per_page?: number;
  order?: string;
  order_direction?: string;
  expand?: string;
  'expand[]'?: string[];
  'id[]'?: string[];
  id?: string;
}
