import { FindParamsUsers, User } from '@domains';
import { createAction, props } from '@ngrx/store';

export enum UserActionType {
  ClearUsersFindParams = '[Users] Clear Users Find Params',
  GetUsers = '[Users] Get Users',
  GetUsersSuccess = '[Users] Get Users Success',

  ClearUser = '[Users] Clear User',
  GetUser = '[Users] Get User',
  GetUserSuccess = '[Users] Get User Success',

  CreateUser = '[Users] Create User',
  CreateUserSuccess = '[Users] Create User Success',

  UpdateUser = '[Users] Update User',
  UpdateUserSuccess = '[Users] Update User Success',

  DeleteUser = '[Users] Delete User',
  DeleteUserSuccess = '[Users] Delete User Success',

  RestoreUser = '[Users] Restore User',
  RestoreUserSuccess = '[Users] Restore User Success',
}

export const clearUsersFindParams = createAction(UserActionType.ClearUsersFindParams);
export const clearUser = createAction(UserActionType.ClearUser);
export const getUsers = createAction(UserActionType.GetUsers, props<{ findParams: FindParamsUsers }>());
export const getUsersSuccess = createAction(UserActionType.GetUsersSuccess, props<{
  resultsCount: number,
  results: Array<User>
}>());


export const getUser = createAction(UserActionType.GetUser, props<{ id: string }>());
export const getUserSuccess = createAction(UserActionType.GetUserSuccess, props<{ user: User }>());

export const createUser = createAction(UserActionType.CreateUser, props<{ user: User }>());
export const createUserSuccess = createAction(UserActionType.CreateUserSuccess, props<{ user: User }>());

export const updateUser = createAction(UserActionType.UpdateUser, props<{ user: User }>());
export const updateUserSuccess = createAction(UserActionType.UpdateUserSuccess, props<{ user: User }>());

export const deleteUser = createAction(UserActionType.DeleteUser, props<{ userId: string }>());
export const deleteUserSuccess = createAction(UserActionType.DeleteUserSuccess, props<{ userId: string }>());

export const restoreUser = createAction(UserActionType.RestoreUser, props<{ userId: string }>());
export const restoreUserSuccess = createAction(UserActionType.RestoreUserSuccess, props<{ userId: string }>());
