import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateAdapter,
  NgxMatDateFormats,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import {
  NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  NgxMatMomentModule,
} from '@angular-material-components/moment-adapter';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  MatSlideToggle,
  MatSlideToggleModule,
} from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ENVIRONMENT, Environment } from '@domains';
import { RsplApiModule } from '@rspl-api';
import { RsplMapModule } from '@rspl-map';
import { RsplTableModule } from '@rspl-table';
import { RsplUIModule } from '@rspl-ui';
import { RsplVersionModule } from '@rspl-version';
import { LaddaModule } from 'angular2-ladda';
import {
  MatTableExporterDirective,
  MatTableExporterModule,
} from 'mat-table-exporter';
import { ColorPickerModule } from 'ngx-color-picker';
import {
  NgxCurrencyDirective,
  NgxCurrencyInputMode,
  provideEnvironmentNgxCurrency,
} from 'ngx-currency';
import { MapService } from 'ngx-mapbox-gl';
import {
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask
} from 'ngx-mask';
import { BaseContainerComponent } from './components/base-container/base-container.component';
import { CharityScreeningComponent } from './components/charity-screening/charity-screening.component';
import { FilterableListComponent } from './components/filterable-list/filterable-list.component';
import { HistoryPageComponent } from './components/history-page/history-page.component';
import { PickStoresComponent } from './components/pick-stores/pick-stores.component';
import { PickTrucksComponent } from './components/pick-trucks/pick-trucks.component';
import { ScheduleHistoryComponent } from './components/schedule-history/schedule-history.component';
import { SaveZoneDialogComponent } from './components/schedule/save-zone-dialog/save-zone-dialog.component';
import { ScheduleFormComponent } from './components/schedule/schedule-form/schedule-form.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { SplitScreenComponent } from './components/split-screen/split-screen.component';
import { UiTemplateComponent } from './components/ui-template/ui-template.component';
import { WebButtonComponent } from './components/web-button/web-button.component';
import { ZipPickerComponent } from './components/zip-picker/zip-picker.component';
import { CustomNgxDatetimeAdapter } from './custom-ngx-datetime-adapter';
import { BreakLinePipe } from './pipes/break-line.pipe';
import { ParseContentPipe } from './pipes/parse-content.pipe';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    // dateInput: 'l, LTS',
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY hh:mm A',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    BaseContainerComponent,
    ScheduleComponent,
    ScheduleFormComponent,
    ZipPickerComponent,
    SaveZoneDialogComponent,
    SplitScreenComponent,
    UiTemplateComponent,
    HistoryPageComponent,
    ScheduleHistoryComponent,
    WebButtonComponent,
    PickStoresComponent,
    PickTrucksComponent,
    FilterableListComponent,
    CharityScreeningComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableExporterModule,
    ColorPickerModule,
    RouterModule,
    LaddaModule.forRoot({
      style: 'zoom-in',
    }),
    NgxCurrencyDirective,

    //rspl
    RsplApiModule,
    RsplUIModule,
    RsplMapModule,
    RsplVersionModule,
    RsplTableModule,

    //material
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatToolbarModule,
    MatCardModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatMenuModule,
    MatTabsModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatTooltipModule,
    CdkStepperModule,
    MatStepperModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatMomentDateModule,
    MatRadioModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatButtonToggleModule,
    NgxMatMomentModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [
    BaseContainerComponent,
    FormsModule,
    ReactiveFormsModule,
    MatTableExporterDirective,
    ScheduleComponent,
    ZipPickerComponent,
    SaveZoneDialogComponent,
    LaddaModule,
    SplitScreenComponent,
    CommonModule,
    UiTemplateComponent,
    HistoryPageComponent,
    ScheduleHistoryComponent,
    ColorPickerModule,
    WebButtonComponent,
    PickStoresComponent,
    PickTrucksComponent,
    CharityScreeningComponent,
    NgxCurrencyDirective,

    //rspl
    RsplApiModule,
    RsplUIModule,
    RsplMapModule,
    RsplVersionModule,
    RsplTableModule,

    //material
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatToolbarModule,
    MatCardModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatMenuModule,
    MatTabsModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatDividerModule,
    MatTooltipModule,
    CdkStepperModule,
    MatStepperModule,
    MatChipsModule,
    MatSlideToggle,
    MatButtonToggleModule,
    DragDropModule,
    MatMomentDateModule,
    MatRadioModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    NgxMatMomentModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    FilterableListComponent,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
})
export class CoreModule {
  public static forRoot(
    environment: Environment
  ): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        CoreModule,
        { provide: ENVIRONMENT, useValue: environment },
        CurrencyPipe,
        BreakLinePipe,
        ParseContentPipe,
        DatePipe,
        MapService,
        {
          provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
          useValue: { useUtc: true },
        },
        {
          provide: NgxMatDateAdapter,
          useClass: CustomNgxDatetimeAdapter,
          deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
        provideNgxMask(),
        provideEnvironmentNgxCurrency({
          align: 'left',
          allowNegative: false,
          allowZero: true,
          decimal: '.',
          precision: 2,
          prefix: '$ ',
          suffix: '',
          thousands: ',',
          nullable: false,
          min: 0,
          inputMode: NgxCurrencyInputMode.Natural,
        }),
      ],
    };
  }
}
