import {
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    AppState,
    createCharityScreening,
    createCharityScreeningSuccess,
    getCharityScreening,
    selectCharityScreening,
    updateCharityScreening,
    updateCharityScreeningSuccess,
} from '@core';
import { Charity, Screening, ScreeningQuestion } from '@domains';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Destructible } from '@rspl-ui';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'rspl-charity-screening',
  templateUrl: './charity-screening.component.html',
  styleUrls: ['./charity-screening.component.scss'],
})
export class CharityScreeningComponent extends Destructible implements OnInit {
  @ViewChild('AddUrlTemplate') addUrlTemplate: TemplateRef<any>;
  #charity: Charity;
  @Input() set charity(charity: Charity) {
    this.#charity = charity;
    this.getScreening();
  }
  get charity(): Charity {
    return this.#charity;
  }
  @Input() readOnlyForm?: boolean;

  public form: FormGroup<{
    zips: FormControl<string[]>;
    disassembly: FormControl<boolean>;
    leadTime: FormControl<string>;
    floor: FormControl<string>;
    restrictedItems: FormControl<string[]>;
    restrictedItemUrls: FormArray<
      FormGroup<{ url: FormControl<string>; name: FormControl<string> }>
    >;
    meta: FormGroup<{
      notes: FormControl<string>;
    }>;
    hiddenFields: FormControl<ScreeningQuestion[]>;
  }> = new FormGroup({
    zips: new FormControl<string[]>([]),
    disassembly: new FormControl<boolean>(null),
    leadTime: new FormControl<string>(null),
    floor: new FormControl<string>(null),
    restrictedItems: new FormControl<string[]>([]),
    restrictedItemUrls: new FormArray<
      FormGroup<{ url: FormControl<string>; name: FormControl<string> }>
    >([]),
    meta: new FormGroup({
      notes: new FormControl(),
    }),
    hiddenFields: new FormControl<ScreeningQuestion[]>([]),
  });

  addUrlForm = new FormGroup({
    name: new FormControl('', Validators.required),
    url: new FormControl('', [
      Validators.required,
      Validators.pattern(
        '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
      ),
    ]),
  });

  questions: { text: string; value: ScreeningQuestion }[];

  addUrlDialog?: MatDialogRef<any>;
  screening?: Screening;

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    private actions: Actions
  ) {
    super();
    this.form.disable();
  }

  ngOnInit(): void {
    this.store
      .pipe(select(selectCharityScreening), takeUntil(this.destroy$))
      .subscribe((screening) => {
        if (!!screening && this.charity.id !== screening.charityId) return;
        this.screening = screening;
        this.restrictedItemUrlsFromControl;
        while (this.restrictedItemUrlsFromControl.length !== 0) {
          this.restrictedItemUrlsFromControl.removeAt(0);
        }

        for (let u of this.screening?.restrictedItemUrls || []) {
          this.restrictedItemUrlsFromControl.push(
            new FormGroup({
              url: new FormControl(null),
              name: new FormControl(null),
            })
          );
        }

        this.form.patchValue({
          ...screening,
          zips: [...(screening?.zips || [])],
          restrictedItems: [...(screening?.restrictedItems || [])],
          hiddenFields: [...(screening?.hiddenFields || [])],
        });

        if (!this.readOnlyForm) {
          this.form.enable();
          this.setQuestions();
        }

      });

    this.actions
      .pipe(
        ofType(updateCharityScreeningSuccess, createCharityScreeningSuccess),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.getScreening());

    this.getScreening();
  }

  getScreening() {
    this.store.dispatch(getCharityScreening({ charityId: this.charity.id }));
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.restrictedItemsFormControl.patchValue([
        ...new Set([...this.restrictedItemsFormControl.value, value]),
      ]);
    }
    event.input.value = '';
  }

  remove(restrictedItem: string): void {
    let restrictedItems = [...this.restrictedItemsFormControl.value];
    const index = restrictedItems.indexOf(restrictedItem);

    if (index >= 0) {
      restrictedItems.splice(index, 1);
      restrictedItems = [...new Set(restrictedItems)];
    }
    this.restrictedItemsFormControl.patchValue(restrictedItems);
  }

  paste(event: ClipboardEvent): void {
    let restrictedItems = [...this.restrictedItemsFormControl.value];
    event.preventDefault();
    event.clipboardData
      .getData('Text')
      .split(/;|\n/) // Splits it when a SEMICOLON or NEWLINE
      .forEach((value) => {
        if (value.trim()) {
          restrictedItems = [...restrictedItems, value.trim()];
        }
      });
    restrictedItems = [...new Set(restrictedItems)];
    this.restrictedItemsFormControl.patchValue(restrictedItems);
  }

  csvData(data: string[]) {
    let restrictedItems = this.restrictedItemsFormControl.value;
    data.forEach((value) => {
      if (value.trim()) {
        restrictedItems = [...restrictedItems, value.trim()];
      }
    });
    restrictedItems = [...new Set(restrictedItems)];
    this.restrictedItemsFormControl.patchValue(restrictedItems);
  }

  addFileUrl(file: { url: string; name: string; type?: string }) {
    const data = this.restrictedItemUrlsFormArray.getRawValue();
    this.restrictedItemUrlsFormArray.push(
      new FormGroup({
        url: new FormControl(),
        name: new FormControl(),
      })
    );
    this.restrictedItemUrlsFormArray.setValue([
      ...data,
      { url: file.url, name: file.name },
    ]);
    this.closeAddUrl();
  }

  removeUrl(i: number) {
    let urls = this.restrictedItemUrlsFormArray.getRawValue();

    urls.splice(i, 1);
    this.restrictedItemUrlsFormArray.removeAt(i);
    this.restrictedItemUrlsFormArray.patchValue(urls);
  }

  save() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
    if (this.form.invalid) return;
    this.form.disable();
    if (this.screening?.id) {
      this.store.dispatch(
        updateCharityScreening({
          screening: new Screening({
            ...this.screening,
            ...this.form.getRawValue(),
          }),
        })
      );
    } else {
      this.store.dispatch(
        createCharityScreening({
          screening: new Screening({
            ...this.form.getRawValue(),
            charityId: this.charity.id,
          }),
        })
      );
    }
  }

  get zipsFormControl(): FormControl<string[]> {
    return this.form.get('zips') as FormControl<string[]>;
  }

  get restrictedItemsFormControl(): FormControl<string[]> {
    return this.form.get('restrictedItems') as FormControl<string[]>;
  }

  get restrictedItemUrlsFormArray(): FormArray<
    FormGroup<{ url: FormControl<string>; name: FormControl<string> }>
  > {
    return this.form.get('restrictedItemUrls') as FormArray<
      FormGroup<{ url: FormControl<string>; name: FormControl<string> }>
    >;
  }

  getRestrictedItemUrlsFormControl(
    i: number
  ): FormGroup<{ url: FormControl<string>; name: FormControl<string> }> {
    return this.restrictedItemUrlsFormArray.controls[i] as FormGroup<{
      url: FormControl<string>;
      name: FormControl<string>;
    }>;
  }

  getRestrictedItemUrlsNameFormControl(i: number): FormControl<string> {
    return this.getRestrictedItemUrlsFormControl(i).get(
      'name'
    ) as FormControl<string>;
  }

  getRestrictedItemUrlsUrlFormControl(i: number): FormControl<string> {
    return this.getRestrictedItemUrlsFormControl(i).get(
      'url'
    ) as FormControl<string>;
  }

  openAddUrl(url?: string, name?: string) {
    this.addUrlForm.setValue({ url: url || '', name: name || '' });
    this.addUrlDialog = this.dialog.open(this.addUrlTemplate, {
      width: '1200px',
      maxWidth: '100vw'
    });
  }

  closeAddUrl() {
    this.addUrlDialog?.close();
    this.addUrlForm.setValue({ url: '', name: '' });
    this.addUrlDialog = undefined;
  }

  addUrl() {
    this.addUrlForm.markAllAsTouched();
    this.addUrlForm.updateValueAndValidity();
    if (this.addUrlForm.invalid) return;
    this.addFileUrl(this.addUrlForm.getRawValue());
  }

  get restrictedItemUrlsFromControl(): FormArray<
    FormGroup<{ url: FormControl<string>; name: FormControl<string> }>
  > {
    return this.form.get('restrictedItemUrls') as FormArray<
      FormGroup<{ url: FormControl<string>; name: FormControl<string> }>
    >;
  }

  get addUrlUrlFromControl(): FormControl<string> {
    return this.addUrlForm.get('url') as FormControl<string>;
  }

  get addUrlNameFromControl(): FormControl<string> {
    return this.addUrlForm.get('name') as FormControl<string>;
  }

  setQuestions() {
    this.questions = [];
    if (this.form.get('disassembly').value === false) {
      this.questions.push({
        text: 'Disassembly',
        value: ScreeningQuestion.DISASSEMBLY,
      });
    }
    if (this.form.get('zips').value.length > 0) {
      this.questions.push({ text: 'Zips', value: ScreeningQuestion.ZIPS });
    }
    if (this.form.get('restrictedItems').value.length > 0) {
      this.questions.push({
        text: 'Restricted Items',
        value: ScreeningQuestion.RESTRICTED_ITEMS,
      });
    }
    if (this.form.get('floor').value !== 'any') {
      this.questions.push({ text: 'Floor', value: ScreeningQuestion.FLOOR });
    }
    this.questions.push({ text: 'Damaged', value: ScreeningQuestion.DAMAGED });
  }
}
