import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ENVIRONMENT, Environment } from '@domains';
import { Observable, map, take } from 'rxjs';
import { AuthenticationService } from '../authentication.service';

@Injectable({ providedIn: 'root' })
export class UserFeatureGuard  {
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    @Inject(ENVIRONMENT) private config: Environment,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const features = route.data['features'] as Array<string>;
    const allowed = route.data['shouldAllowFeatures'] as boolean;
    return this.authService.authData$.pipe(
      take(1),
      map((authData) => {
        for (const feature of features) {
          if (authData?.meta && (authData.meta as any)[feature]) {
            return allowed;
          }
        }
        this.snackBar.open('Access Not Allowed', 'x', {
          duration: 5000,
      panelClass: 'error'
        });
        this.router.navigate(this.config.defaultRoute);
        return false;
      })
    );
  }
}
