import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  Anomaly,
  BaseDonationLead,
  Donation,
  ENVIRONMENT,
  Environment,
} from '@domains';
import { Deserialize, Serialize } from 'cerialize';
import { BaseApiService } from '../base-api.service';

@Injectable({
  providedIn: 'root',
})
export class AnomaliesService extends BaseApiService<Anomaly> {
  constructor(
    @Inject(ENVIRONMENT) override config: Environment,
    override http: HttpClient
  ) {
    super(config, http, 'anomalies', Anomaly, 'Anomaly');
  }

  override serialize(item: Anomaly) {
    return {
      ...Serialize({ ...item }, Anomaly),
    };
  }

  override deserialize(data: any): Anomaly {
    const res = new Anomaly({
      ...Deserialize(data, Anomaly),
      source: data.source
        ? {
            ...Deserialize(data.source, Donation),
            ...Deserialize(
              {
                ...data.source,
              },
              BaseDonationLead
            ),
          }
        : undefined,
    });
    res.setInitialValue();
    return res;
  }
}
