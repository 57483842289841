import { Anomaly } from '@domains';
import { Action, createReducer, on } from '@ngrx/store';
import * as fromActions from './anomaly.actions';

export interface AnomalyState {
  anomalies: Array<Anomaly>;
  anomaliesTotalCount: number;
}

const initialState: AnomalyState = {
  anomalies: new Array<Anomaly>(),
  anomaliesTotalCount: 0,
};

const reducer = createReducer(
  initialState,


  on(fromActions.getAnomalies, (state, {findParams, keep}) => {
    return {...state, anomalies: keep ? state.anomalies : []};
  }),
  on(fromActions.getAnomaliesSuccess, (state, {anomalies, resultsCount}) => ({...state, anomalies, anomaliesTotalCount: resultsCount})),
  on(fromActions.clearAnomaliesFindParams, state => {
    return {...state};
  }),

);

export function anomalyReducer(state: AnomalyState | undefined, action: Action) {
  return reducer(state, action);
}
