import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AnomalyState } from './anomaly.reducers';

// tslint:disable: max-line-length
export const anomaliesFeatureStateSelector =
  createFeatureSelector<AnomalyState>('anomaly');

export const selectAnomalies = createSelector(
  anomaliesFeatureStateSelector,
  (state: AnomalyState) => state.anomalies
);
export const selectAnomaliesTotalCount = createSelector(
  anomaliesFeatureStateSelector,
  (state: AnomalyState) => state.anomaliesTotalCount
);
