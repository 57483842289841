<h2 mat-dialog-title>Columns</h2>
<div mat-dialog-content>
  <div fxLayout="row">
    <div fxLayout="column">
      <div fxLayout="row" appearance="outline" fxFlex *ngFor="let column of data.columns">
        <mat-checkbox
          class="example-margin"
          color="primary"
          *ngIf="data.config[column.field].editable"
          [checked]="!data.config[column.field].hidden"
          (change)="data.config[column.field].hidden = !$event.checked"
        >
          {{data.config[column.field].title}}
        </mat-checkbox>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end">
  <button mat-button (click)="reference.close()">Cancel</button>
  <button class="primary-btn" mat-raised-button (click)="reference.close(data.config)">Save</button>
</div>
