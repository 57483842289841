import { FindParams } from "./find-params";
import { LeadPhoneType } from "./lead-phone-type";

export interface FindLeadsParams extends FindParams {
  charity_id?: string;
  date_after?: string;
  date_before?: string;
  created_after?: string;
  created_before?: string;
  'call_details.phone_type'?: LeadPhoneType;
}
