<div fxLayout="column" fxLayoutGap="10px">
  <mat-form-field
    class="zone-input"
    appearance="outline"
    [ngClass]="{ invalid: !disabled && required && !(zips?.length > 0) }"
  >
    <mat-label>{{ label }}</mat-label>
    <mat-chip-grid
      #chipList
      [disabled]="disabled"
      aria-label="Zip code selection"
    >
      <mat-chip-row
        *ngFor="let zip of zips"
        [removable]="true"
        (removed)="remove(zip)"
        [disabled]="disabled"
      >
        {{ zip }}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
      <input
        [matChipInputFor]="chipList"
        [matChipInputAddOnBlur]="false"
        (matChipInputTokenEnd)="add($event)"
        (paste)="paste($event)"
        [readOnly]="disabled"
      />
    </mat-chip-grid>
    <mat-icon
      (click)="copy()"
      *ngIf="zips?.length > 0 && windowSize !== windowSizes.XS"
      class="copy"
      title="Copy zips to clipboard"
      matSuffix
      >content_copy
    </mat-icon>
  </mat-form-field>
  <p
    class="error"
    fxLayout="row"
    *ngIf="showErrorMessage && !disabled && required && !(zips?.length > 0)"
  >
    Zips are required
  </p>
  <div fxLayout="row">
    <button
      class="primary-color"
      mat-button
      [ngClass]="{
        'block-btn': windowSize === windowSizes.XS
      }"
      style="margin-bottom: 20px"
      (click)="copy()"
      *ngIf="zips?.length > 0 && windowSize === windowSizes.XS"
    >
      Copy zips to clipboard
    </button>
  </div>
</div>
