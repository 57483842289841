import { NgxCurrencyInputMode } from 'ngx-currency';

export const simpleNumberFormat = {
  align: 'center',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: null,
  prefix: '',
  suffix: '',
  thousands: ',',
  nullable: false,
  min: 0,
  max: null,
  inputMode: NgxCurrencyInputMode.Natural,
};
