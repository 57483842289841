import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { JourneysService } from '@rspl-api';
import * as fromActions from './journey.actions';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';


@Injectable()
export class JourneysEffects {

  getJourney = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getJourney),
      switchMap((params) =>
        this.service.getJourney(params.findParams)
          .pipe(
            map((result) => {
                return fromActions.getJourneySuccess({
                  journey: result
                });
              }
            ),
            catchError(() => EMPTY)
          )
      )
    );
  });

  updateJourney = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateJourney),
      mergeMap((payload) => {
        return this.service.update(payload.journey.id, payload.journey).pipe(
          map((result) => {
            return fromActions.updateJourneySuccess({
              journey: result,
            });
          }),
          catchError(() => [fromActions.updateJourneyFail()])
        );
      })
    );
  });

  optimizeJourney = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.optimizeJourney),
      switchMap((params) =>
        this.service.optimizeJourney(params.journeyId)
          .pipe(
            map((result) => {
                return fromActions.optimizeJourneySuccess({
                  journey: result,
                });
              }
            ),
            catchError(() => [fromActions.optimizeJourneyFail()])
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private service: JourneysService,
  ) {
  }
}
