<rspl-card [cardTitle]="charity.name + ' Screening'">
  <form [formGroup]="form" #ngForm="ngForm" readonly="readOnlyForm">
    <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutGap="20px">
      <div fxFlex="50" fxLayoutGap="20px" fxLayout="column">
        <div fxLayout="column">
          <app-zip-picker
            label="Free Zips"
            #zone
            fxFlex
            [disabled]="readOnlyForm"
            [zips]="zipsFormControl.value"
            [required]="false"
            (scheduleUpdated)="
              zipsFormControl.patchValue($event); setQuestions()
            "
          ></app-zip-picker>
        </div>
        <div
          fxLayout="column"
          [ngClass]="{
            invalid: ngForm.submitted && form.get('floor').invalid
          }"
        >
          <label>What floor can drivers pickup?</label>
          <mat-radio-group
            formControlName="floor"
            fxLayout="row wrap"
            fxLayout.lt-md="column"
            fxLayoutGap="10px"
            (change)="setQuestions()"
          >
            <mat-radio-button value="curbside">Curbside</mat-radio-button>
            <mat-radio-button value="first">1st only</mat-radio-button>
            <mat-radio-button value="second"
              >2nd floor and below</mat-radio-button
            >
            <mat-radio-button value="any">Any Floor</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div fxFlex="50" fxLayoutGap="20px" fxLayout="column">
        <div
          fxLayout="column"
          [ngClass]="{
            invalid: ngForm.submitted && form.get('disassembly').invalid
          }"
        >
          <label>Disassembly</label>
          <mat-radio-group
            formControlName="disassembly"
            fxLayout="row wrap"
            fxLayout.lt-md="column"
            fxLayoutGap="10px"
            (change)="setQuestions()"
          >
            <mat-radio-button [value]="true">Yes</mat-radio-button>
            <mat-radio-button [value]="false">No</mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          fxLayout="column"
          [ngClass]="{
            invalid: ngForm.submitted && form.get('leadTime').invalid
          }"
        >
          <label>Lead time</label>
          <mat-radio-group
            formControlName="leadTime"
            fxLayout="row wrap"
            fxLayout.lt-md="column"
            fxLayoutGap="10px"
            (change)="setQuestions()"
          >
            <mat-radio-button value="3 days or less"
              >3 days or less</mat-radio-button
            >
            <mat-radio-button value="3-5 Days">3-5 Days</mat-radio-button>
            <mat-radio-button value="5-7 Days">5-7 Days</mat-radio-button>
            <mat-radio-button value="1-2 Weeks">1-2 Weeks</mat-radio-button>
            <mat-radio-button value="2-3 Weeks">2-3 Weeks</mat-radio-button>
            <mat-radio-button value="3-4 Weeks">3-4 Weeks</mat-radio-button>
            <mat-radio-button value="4+ Weeks">4+ Weeks</mat-radio-button>
          </mat-radio-group>
        </div>
        <div fxLayout.gt-sm="row" fxLayout="column" fxLayoutGap="20px">
          <div fxLayout="column" fxFlex="60">
            <mat-form-field appearance="outline">
              <mat-label>Restricted Items</mat-label>
              <mat-chip-grid
                #restrictedList
                [disabled]="readOnlyForm"
                aria-label="Restricted zip code selection"
                (change)="setQuestions()"
              >
                <mat-chip-row
                  *ngFor="let zip of restrictedItemsFormControl.value"
                  [removable]="true"
                  (removed)="remove(zip)"
                >
                  {{ zip }}
                  <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
                <input
                  [matChipInputFor]="restrictedList"
                  [matChipInputAddOnBlur]="false"
                  (matChipInputTokenEnd)="add($event)"
                  (paste)="paste($event)"
                />
              </mat-chip-grid>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutAlign="start start" fxFlex="40">
            <label>Files & Urls</label>
            <div
              *ngIf="!readOnlyForm"
              fxLayout.gt-lg="row"
              fxLayout="column"
              fxLayoutGap="10px"
              style="width: 100%"
            >
              <rspl-file-upload
                type="file"
                (uploadedFileUrl)="addFileUrl($event)"
                (csvData)="csvData($event)"
                fxFlex.gt-lg="50"
                fxFlex="100"
              ></rspl-file-upload>
              <button
                class="primary-btn"
                fxFlex.gt-lg="50"
                fxFlex="100"
                mat-raised-button
                (click)="openAddUrl()"
              >
                Add Link
              </button>
            </div>
            <ul fxLayout="column" fxLayoutAlign="start start">
              <li
                *ngFor="
                  let file of restrictedItemUrlsFormArray.value;
                  let i = index
                "
                fxLayoutAlign="start center"
              >
                <ng-container
                  *ngIf="
                    !!getRestrictedItemUrlsFormControl(i) &&
                    getRestrictedItemUrlsUrlFormControl(i)
                  "
                >
                  <a
                    *ngIf="readOnlyForm"
                    (click)="
                      openAddUrl(
                        getRestrictedItemUrlsUrlFormControl(i).value,
                        getRestrictedItemUrlsNameFormControl(i).value
                      )
                    "
                  >
                    - {{ getRestrictedItemUrlsNameFormControl(i).value }}
                  </a>
                  <a
                    *ngIf="!readOnlyForm"
                    [href]="getRestrictedItemUrlsUrlFormControl(i).value"
                    target="_blank"
                    >- {{ getRestrictedItemUrlsNameFormControl(i).value }}
                  </a>
                  <button
                    mat-icon-button
                    (click)="removeUrl(i)"
                    *ngIf="!readOnlyForm"
                  >
                    <mat-icon color="warn"> close </mat-icon>
                  </button>
                </ng-container>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row">
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Hide Questions</mat-label>
        <mat-select
          matInput
          formControlName="hiddenFields"
          disableOptionCentering="true"
          #select
          multiple
        >
          <mat-option *ngFor="let option of questions" [value]="option.value">
            {{ option.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" formGroupName="meta">
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>Notes</mat-label>
        <textarea formControlName="notes" matInput rows="5"></textarea>
      </mat-form-field>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="end center"
      [style.margin-top.px]="30"
      *ngIf="!readOnlyForm"
    >
      <button class="primary-btn" mat-raised-button (click)="save()">
        Save
      </button>
    </div>
  </form>
</rspl-card>

<ng-template #AddUrlTemplate>
  <h2 mat-dialog-title>
    {{ readOnlyForm ? '' : 'Add' }} Restricted Items Link
  </h2>
  <form mat-dialog-content fxLayout="column" [formGroup]="addUrlForm">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Title</mat-label>
      <input
        formControlName="name"
        matInput
        required
        [readonly]="readOnlyForm"
      />
      <mat-error
        *ngIf="
          addUrlNameFromControl.errors &&
          addUrlNameFromControl.errors['required']
        "
        >Title is required</mat-error
      >
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>URL</mat-label>
      <input
        formControlName="url"
        matInput
        required
        [readonly]="readOnlyForm"
      />
      <mat-error
        *ngIf="
          addUrlUrlFromControl.errors && addUrlUrlFromControl.errors['required']
        "
        >URL is required
      </mat-error>
      <mat-error
        *ngIf="
          addUrlUrlFromControl.errors && addUrlUrlFromControl.errors['pattern']
        "
        >URL is not valid
      </mat-error>
    </mat-form-field>
    <a
      class="link"
      [href]="addUrlForm.controls.url.value"
      target="_blank"
      *ngIf="!addUrlUrlFromControl.errors"
      >Open URL in new tab</a
    >
    <iframe
      width="100%"
      height="500px"
      [src]="
        (addUrlUrlFromControl.valid ? addUrlUrlFromControl.value : '') | safe
      "
    >
    </iframe>
  </form>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="flex-end">
    <button mat-button (click)="closeAddUrl()">Cancel</button>
    <button
      *ngIf="!readOnlyForm"
      class="primary-btn filled-btn"
      mat-raised-button
      rsplDisableDoubleClick
      (oneClick)="addUrl()"
    >
      Add
    </button>
  </div>
</ng-template>
