import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { ENVIRONMENT, Environment, Permissions } from '@domains';
import { LocalStorageService } from '@rspl-api';
import { Observable, map, take } from 'rxjs';
import { AFTER_LOGIN, AuthenticationService } from '../authentication.service';

@Injectable({ providedIn: 'root' })
export class PermissionGuard {
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    @Inject(ENVIRONMENT) private config: Environment,
    private localStorage: LocalStorageService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const allowedPermissions = route.data['permissions'] as Array<Permissions>;
    return this.authService.authData$.pipe(
      take(1),
      map((authData) => {
        if (authData?.authorization?.auth) {
          for (const allowedPermission of allowedPermissions) {
            if (
              authData?.permissions &&
              authData?.permissions[allowedPermission]
            ) {
              return true;
            }
          }
          this.snackBar.open('Access Not Allowed', 'x', {
            duration: 5000,
            panelClass: 'error',
          });
          if (!this.config.production) {
            this.localStorage.setItem(AFTER_LOGIN, state.url);
            this.authService.logout();
          } else {
            this.router.navigate(this.config.defaultRoute);
          }
          return false;
        } else {
          this.localStorage.setItem(AFTER_LOGIN, state.url);
          this.authService.logout();
          return false;
        }
      })
    );
  }
}
