import {
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Day, ENVIRONMENT, Environment, Schedule, ScheduleLeadTime, Zone, days, halfHours, hours } from '@domains';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { MapComponent } from '@rspl-map';
import { Responsive, ResponsiveService } from '@rspl-ui';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { SaveZoneDialogComponent } from '../../../components/schedule/save-zone-dialog/save-zone-dialog.component';
import { ScheduleConfig } from '../../../components/schedule/schedule-config';
import { ZipPickerComponent } from '../../../components/zip-picker/zip-picker.component';
import { AppState } from '../../../store/app-state.interface';
import { getZones } from '../../../store/zone/zone.actions';
import { selectZones } from '../../../store/zone/zone.selectors';

type TimeType = FormGroup<{
  closed: FormControl<boolean>;
  opens: FormControl<string>;
  closes: FormControl<string>;
}>;

type ZoneType = FormGroup<{
  amZips: FormControl<string[]>;
  pmZips: FormControl<string[]>;
}>;

type CapacityType = FormGroup<{
  amCapacity: FormControl<number>;
  pmCapacity: FormControl<number>;
}>;

@Component({
  selector: 'app-schedule-form',
  templateUrl: './schedule-form.component.html',
  styleUrls: ['./schedule-form.component.scss'],
})
export class ScheduleFormComponent extends Responsive {
  isCaptain;
  #config!: ScheduleConfig;
  @Input() showWarning = true;
  @Input() showMap = true;
  @Input() day!: Day;

  @Input() set config(config: ScheduleConfig) {
    this.#config = config;
    if (this.config) {
      this.init();
    }
  }

  get config(): ScheduleConfig {
    return this.#config;
  }

  @Input() isDefault = true;
  @Input() year?: number;
  @Input() week?: number;
  @Output() scheduleSaved = new EventEmitter();

  #zipPickerComponent!: MapComponent;
  @ViewChild(MapComponent) set zipPickerComponent(
    zipPickerComponent: MapComponent
  ) {
    this.#zipPickerComponent = zipPickerComponent;
    if (this.zipPickerComponent && this.config.address) {
      this.zipPickerComponent.setAddress(this.config.address, false);
    }
  }

  get zipPickerComponent(): MapComponent {
    return this.#zipPickerComponent;
  }

  #zoneComponents!: QueryList<ZipPickerComponent>;
  @ViewChildren('zone') set zoneComponents(
    zoneComponents: QueryList<ZipPickerComponent>
  ) {
    this.#zoneComponents = zoneComponents;
    if (this.zoneComponents && this.schedule) {
      this.patchZones();
    }
  }

  get zoneComponents(): QueryList<ZipPickerComponent> {
    return this.#zoneComponents;
  }

  parentId: string;
  partnerId: string;
  charityId: string;
  schedule!: Schedule;
  form!: FormGroup<{
    id: FormControl<string>;
    week: FormControl<number>;
    year: FormControl<number>;
    mondayTime: TimeType;
    mondayCapacity: CapacityType;
    mondayZone: ZoneType;
    tuesdayTime: TimeType;
    tuesdayCapacity: CapacityType;
    tuesdayZone: ZoneType;
    wednesdayTime: TimeType;
    wednesdayCapacity: CapacityType;
    wednesdayZone: ZoneType;
    thursdayTime: TimeType;
    thursdayCapacity: CapacityType;
    thursdayZone: ZoneType;
    fridayTime: TimeType;
    fridayCapacity: CapacityType;
    fridayZone: ZoneType;
    saturdayTime: TimeType;
    saturdayCapacity: CapacityType;
    saturdayZone: ZoneType;
    sundayTime: TimeType;
    sundayCapacity: CapacityType;
    sundayZone: ZoneType;
    leadTime?: FormControl<ScheduleLeadTime>;
  }>;
  days = days;
  selectedDay: Day = this.days[0];
  fetched = false;
  weekMissing = false;
  zones?: Zone[];
  filteredZones?: Zone[];

  leadTimes = [
    ScheduleLeadTime.H24,
    ScheduleLeadTime.H48,
    ScheduleLeadTime.H72,
  ];

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private actions: Actions,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    public override responsiveService: ResponsiveService,
    @Inject(ENVIRONMENT) private environment: Environment
  ) {
    super(responsiveService);
    this.isCaptain = environment.isCaptain;
    this.parentId = this.route.snapshot.params['id'];
    this.partnerId = this.route.snapshot.params['partnerId'];
    this.charityId = this.route.snapshot.params['charityId'];
  }

  init(): void {
    this.parentId = this.config.parentId || this.parentId;
    this.partnerId = this.config.partnerId || this.partnerId;
    this.charityId = this.config.charityId || this.charityId;
    this.store.dispatch(
      this.config.findAction({
        parentId: this.parentId,
        findParams: {
          page: 1,
          per_page: 1,
          ...(this.isDefault ? { default: true } : {}),
          ...(!this.isDefault ? { selected: this.week + '-' + this.year } : {}),
        },
      })
    );
    this.getZones();
    this.actions
      .pipe(ofType(this.config.findActionSuccess), takeUntil(this.destroy$))
      .subscribe((data) => {
        this.fetched = true;
        this.form?.enable();
      });
    this.store
      .pipe(select(selectZones), takeUntil(this.destroy$))
      .subscribe((zones) => {
        this.zones = zones;
        this.filterZones();
      });
    this.store
      .pipe(select(this.config.scheduleSelector), takeUntil(this.destroy$))
      .subscribe((schedule) => {
        if (schedule?.length > 0 || this.isDefault) {
          this.selectedDay = this.day || this.days[0];
          if (!this.isDefault && !(schedule[0].week && schedule[0].year)) {
            this.weekMissing = true;
          }
          this.schedule = schedule[0];
          this.initForm();
          if (!this.schedule) {
            this.schedule = new Schedule();
          } else if (this.config.parentType === 'Truck') {
            this.schedule = new Schedule({
              ...this.schedule,
              leadTime: this.schedule.leadTime || ScheduleLeadTime.H48,
            });
          }
          this.patchForm();
          this.patchZones();
        }
      });
    this.actions
      .pipe(
        ofType(
          this.config.createActionSuccess,
          this.config.updateActionSuccess
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (this.isDefault) {
          this.config.defaultCreatedSuccess && this.config.defaultCreatedSuccess();
        }
        this.snackBar.open(
          this.config.parentType + ' Schedule saved successfully!',
          undefined,
          {
            duration: 5000,
            panelClass: 'success',
          }
        );
      });
  }

  private initForm(): void {
    this.form = new FormGroup({
      id: new FormControl<string>(''),
      week: new FormControl(this.week),
      year: new FormControl(this.year),
      mondayTime: this.createTimeGroup(),
      mondayCapacity: this.createCapacityGroup(),
      mondayZone: this.createZoneGroup(),
      tuesdayTime: this.createTimeGroup(),
      tuesdayCapacity: this.createCapacityGroup(),
      tuesdayZone: this.createZoneGroup(),
      wednesdayTime: this.createTimeGroup(),
      wednesdayCapacity: this.createCapacityGroup(),
      wednesdayZone: this.createZoneGroup(),
      thursdayTime: this.createTimeGroup(),
      thursdayCapacity: this.createCapacityGroup(),
      thursdayZone: this.createZoneGroup(),
      fridayTime: this.createTimeGroup(),
      fridayCapacity: this.createCapacityGroup(),
      fridayZone: this.createZoneGroup(),
      saturdayTime: this.createTimeGroup(),
      saturdayCapacity: this.createCapacityGroup(),
      saturdayZone: this.createZoneGroup(),
      sundayTime: this.createTimeGroup(),
      sundayCapacity: this.createCapacityGroup(),
      sundayZone: this.createZoneGroup(),
    });
    if (this.config.parentType === 'Truck')
      this.form.addControl('leadTime', new FormControl());
  }

  createTimeGroup(): TimeType {
    return new FormGroup({
      closed: new FormControl(false),
      opens: new FormControl(null),
      closes: new FormControl(null),
    });
  }

  private createZoneGroup(): ZoneType {
    return new FormGroup({
      amZips: new FormControl(null, Validators.required),
      pmZips: new FormControl(null, Validators.required),
    });
  }

  private createCapacityGroup(): CapacityType {
    return new FormGroup({
      amCapacity: new FormControl(null),
      pmCapacity: new FormControl(null),
    });
  }

  private patchForm(): void {
    this.form.patchValue({
      id: this.weekMissing ? null : this.schedule?.id,
      mondayTime: {
        closed: this.schedule?.mondayTime?.closed,
        opens: this.schedule?.mondayTime?.opens,
        closes: this.schedule?.mondayTime?.closes,
      },
      mondayCapacity: {
        amCapacity: this.schedule?.mondayCapacity?.amCapacity || 0,
        pmCapacity: this.schedule?.mondayCapacity?.pmCapacity || 0,
      },
      mondayZone: {
        amZips: this.schedule?.mondayZone?.amZips || [],
        pmZips: this.schedule?.mondayZone?.pmZips || [],
      },
      tuesdayTime: {
        closed: this.schedule?.tuesdayTime?.closed,
        opens: this.schedule?.tuesdayTime?.opens,
        closes: this.schedule?.tuesdayTime?.closes,
      },
      tuesdayCapacity: {
        amCapacity: this.schedule?.tuesdayCapacity?.amCapacity || 0,
        pmCapacity: this.schedule?.tuesdayCapacity?.pmCapacity || 0,
      },
      tuesdayZone: {
        amZips: this.schedule?.tuesdayZone?.amZips || [],
        pmZips: this.schedule?.tuesdayZone?.pmZips || [],
      },
      wednesdayTime: {
        closed: this.schedule?.wednesdayTime?.closed,
        opens: this.schedule?.wednesdayTime?.opens,
        closes: this.schedule?.wednesdayTime?.closes,
      },
      wednesdayCapacity: {
        amCapacity: this.schedule?.wednesdayCapacity?.amCapacity || 0,
        pmCapacity: this.schedule?.wednesdayCapacity?.pmCapacity || 0,
      },
      wednesdayZone: {
        amZips: this.schedule?.wednesdayZone?.amZips || [],
        pmZips: this.schedule?.wednesdayZone?.pmZips || [],
      },
      thursdayTime: {
        closed: this.schedule?.thursdayTime?.closed,
        opens: this.schedule?.thursdayTime?.opens,
        closes: this.schedule?.thursdayTime?.closes,
      },
      thursdayCapacity: {
        amCapacity: this.schedule?.thursdayCapacity?.amCapacity || 0,
        pmCapacity: this.schedule?.thursdayCapacity?.pmCapacity || 0,
      },
      thursdayZone: {
        amZips: this.schedule?.thursdayZone?.amZips || [],
        pmZips: this.schedule?.thursdayZone?.pmZips || [],
      },
      fridayTime: {
        closed: this.schedule?.fridayTime?.closed,
        opens: this.schedule?.fridayTime?.opens,
        closes: this.schedule?.fridayTime?.closes,
      },
      fridayCapacity: {
        amCapacity: this.schedule?.fridayCapacity?.amCapacity || 0,
        pmCapacity: this.schedule?.fridayCapacity?.pmCapacity || 0,
      },
      fridayZone: {
        amZips: this.schedule?.fridayZone?.amZips || [],
        pmZips: this.schedule?.fridayZone?.pmZips || [],
      },
      saturdayTime: {
        closed: this.schedule?.saturdayTime?.closed,
        opens: this.schedule?.saturdayTime?.opens,
        closes: this.schedule?.saturdayTime?.closes,
      },
      saturdayCapacity: {
        amCapacity: this.schedule?.saturdayCapacity?.amCapacity || 0,
        pmCapacity: this.schedule?.saturdayCapacity?.pmCapacity || 0,
      },
      saturdayZone: {
        amZips: this.schedule?.saturdayZone?.amZips || [],
        pmZips: this.schedule?.saturdayZone?.pmZips || [],
      },
      sundayTime: {
        closed: this.schedule?.sundayTime?.closed,
        opens: this.schedule?.sundayTime?.opens,
        closes: this.schedule?.sundayTime?.closes,
      },
      sundayCapacity: {
        amCapacity: this.schedule?.sundayCapacity?.amCapacity || 0,
        pmCapacity: this.schedule?.sundayCapacity?.pmCapacity || 0,
      },
      sundayZone: {
        amZips: this.schedule?.sundayZone?.amZips || [],
        pmZips: this.schedule?.sundayZone?.pmZips || [],
      },
    });
    for (const item of this.days) {
      this.dayToggle(item, this.schedule[item + 'Time'].closed || false);
      if (!this.selectedDay && !this.schedule[item + 'Time'].closed) {
        this.selectDay(item);
      }
    }
    if (this.zoneComponents && this.schedule) {
      for (let i = 0; i < this.zoneComponents?.length; i++) {
        this.zoneComponents.get(i).zips = [
          ...this.schedule[this.days[i] + 'Zone'].amZips,
        ];
      }
    }
    if (this.selectedDay) {
      this.updatedZips(this.schedule[this.selectedDay + 'Zone']?.amZips);
    }
    if (this.config.parentType === 'Truck')
      this.leadTime.patchValue(this.schedule.leadTime);
  }

  get leadTime(): FormControl {
    return this.form?.get('leadTime') as FormControl;
  }

  setLeadTime(leadTime: ScheduleLeadTime) {
    this.leadTime.setValue(leadTime);
  }

  getTimeGroup(name): TimeType {
    return this.form.get(name) as TimeType;
  }

  getZoneGroup(name): ZoneType {
    return this.form.get(name) as ZoneType;
  }

  getCapacityGroup(name): CapacityType {
    return this.form.get(name) as CapacityType;
  }

  getClosedControl(group: string): FormControl<boolean> {
    return this.getTimeGroup(group).get('closed') as FormControl<boolean>;
  }

  getAmCapacityControl(group: string): FormControl<number> {
    return this.getCapacityGroup(group).get(
      'amCapacity'
    ) as FormControl<number>;
  }

  getPmCapacityControl(group: string): FormControl<number> {
    return this.getCapacityGroup(group).get(
      'pmCapacity'
    ) as FormControl<number>;
  }

  getOpensControl(group: string): FormControl<string> {
    return this.getTimeGroup(group).get('opens') as FormControl<string>;
  }

  getClosesControl(group: string): FormControl<string> {
    return this.getTimeGroup(group).get('closes') as FormControl<string>;
  }

  getTitle(day: Day | string): string {
    let date;
    if (!this.isDefault && this.week && this.year) {
      date = moment().year(this.year).week(this.week).startOf('week');
    }
    return day
      ? this.isDefault
        ? day[0].toUpperCase() + day.slice(1)
        : date
            ?.clone()
            .add(this.days.indexOf(day as Day), 'days')
            .format('ddd MMM DD, y') || ''
      : '';
  }

  submitForm(): void {
    if (this.form.invalid) {
      return;
    }
    if (this.schedule?.id && !this.weekMissing) {
      this.store.dispatch(
        this.config.updateAction({
          parentId: this.parentId,
          schedule: new Schedule(this.form.getRawValue()),
        })
      );
    } else {
      this.store.dispatch(
        this.config.createAction({
          parentId: this.parentId,
          schedule: new Schedule(this.form.getRawValue()),
        })
      );
    }
  }

  updatedZips(zips: string[]): void {
    if (this.config.zonesPerDay) {
      this.getZoneGroup(this.selectedDay + 'Zone')
        .get('amZips')
        .patchValue(zips);
      this.getZoneGroup(this.selectedDay + 'Zone')
        .get('pmZips')
        .patchValue(zips);
      if (this.zoneComponents?.get(this.days?.indexOf(this.selectedDay))) {
        this.zoneComponents.get(this.days.indexOf(this.selectedDay)).zips = [
          ...zips,
        ];
      }
    } else {
      this.days.forEach((day, i) => {
        this.getZoneGroup(day + 'Zone')
          .get('amZips')
          .patchValue(zips);
        this.getZoneGroup(day + 'Zone')
          .get('pmZips')
          .patchValue(zips);
        if (this.zoneComponents?.get(i)) {
          this.zoneComponents.get(i).zips = [...zips];
        }
      });
    }
  }

  getDayZips(day: string): string[] {
    return this.getZoneGroup(day + 'Zone')?.get('amZips')?.value;
  }

  dayToggle(day: string, closed: boolean): void {
    if (!closed) {
      this.getZoneGroup(day + 'Zone')
        .get('amZips')
        .setValidators(Validators.required);
      this.getZoneGroup(day + 'Zone')
        .get('pmZips')
        .setValidators(Validators.required);
    } else {
      this.getZoneGroup(day + 'Zone')
        .get('amZips')
        .clearValidators();
      this.getZoneGroup(day + 'Zone')
        .get('pmZips')
        .clearValidators();
      if (this.selectedDay === day) {
        this.selectedDay = this.days.find(
          (x) => !this.getTimeGroup(day + 'Time').get('closed').value
        );
      }
    }
    this.getZoneGroup(day + 'Zone')
      .get('amZips')
      .updateValueAndValidity();
    this.getZoneGroup(day + 'Zone')
      .get('pmZips')
      .updateValueAndValidity();
    this.getClosedControl(day + 'Time').patchValue(closed);
    if (closed) {
      this.getAmCapacityControl(day + 'Capacity').disable();
      this.getAmCapacityControl(day + 'Capacity').clearValidators();

      this.getPmCapacityControl(day + 'Capacity').disable();
      this.getPmCapacityControl(day + 'Capacity').clearValidators();

      this.getOpensControl(day + 'Time').disable();
      this.getOpensControl(day + 'Time').clearValidators();

      this.getClosesControl(day + 'Time').disable();
      this.getClosesControl(day + 'Time').clearValidators();
    } else {
      this.getAmCapacityControl(day + 'Capacity').enable();
      this.getAmCapacityControl(day + 'Capacity').setValidators(
        Validators.required
      );

      this.getPmCapacityControl(day + 'Capacity').enable();
      this.getPmCapacityControl(day + 'Capacity').setValidators(
        Validators.required
      );

      this.getOpensControl(day + 'Time').enable();
      this.getOpensControl(day + 'Time').setValidators(Validators.required);

      this.getClosesControl(day + 'Time').enable();
      this.getClosesControl(day + 'Time').setValidators(Validators.required);
    }
    this.getAmCapacityControl(day + 'Capacity').updateValueAndValidity();
    this.getPmCapacityControl(day + 'Capacity').updateValueAndValidity();
    this.getOpensControl(day + 'Time').updateValueAndValidity();
    this.getClosesControl(day + 'Time').updateValueAndValidity();
  }

  getZips(day: Day): string[] {
    const zips = this.schedule ? this.schedule[day + 'Zone'].amZips : [];
    return zips;
  }

  selectDay(day: Day | string): void {
    this.selectedDay = day as Day;
  }

  fetchData(week?: number, year?: number): void {
    this.week = week || this.week;
    this.year = year || this.year;
    this.weekMissing = false;
    this.fetched = false;
    this.form.disable();
    this.store.dispatch(
      this.config.findAction({
        parentId: this.parentId,
        findParams: {
          page: 1,
          per_page: 1,
          ...(!week && !year ? { default: true } : {}),
          ...(week && year ? { selected: week + '-' + year } : {}),
        },
      })
    );
  }

  saveZone(): void {
    const reference = this.dialog.open(SaveZoneDialogComponent, {
      width: '500px',
      data: {
        title: 'Save Zone',
        zone: {
          zips: this.getZoneGroup(this.selectedDay + 'Zone').get('amZips')
            .value,
          ...(this.charityId
            ? {
                charityId: this.charityId,
              }
            : {}),
          ...(this.partnerId
            ? {
                partnerId: this.partnerId,
              }
            : {}),
        },
      },
    });
    reference.afterClosed().subscribe((result) => {
      if (!!result) {
        this.getZones();
      }
    });
  }

  getZones(): void {
    this.store.dispatch(
      getZones({
        findParams: {
          ...(this.charityId
            ? {
                charity_id: this.charityId,
              }
            : {}),
          ...(this.partnerId
            ? {
                partner_id: this.partnerId,
              }
            : {}),
        },
      })
    );
  }

  useZone(zone: Zone): void {
    const zips = [
      ...this.getZoneGroup(this.selectedDay + 'Zone').get('amZips').value,
    ];
    zone.zips.forEach((zip) => {
      if (!zips.includes(zip)) {
        zips.push(zip);
      }
    });
    this.updatedZips(zips);
  }

  filterZones(filter?: string, zoneFilter?: HTMLInputElement): void {
    if (zoneFilter) {
      this.zonesOpened(zoneFilter);
    }
    this.filteredZones = filter
      ? this.zones.filter((z) =>
          z.name.toUpperCase().includes(filter?.toUpperCase())
        )
      : this.zones;
  }

  zonesOpened(zoneFilter: HTMLInputElement, timeout: number = 0): void {
    setTimeout(() => {
      zoneFilter.focus();
    }, timeout);
  }

  get showDays(): string[] {
    return this.day ? [this.day] : this.days;
  }

  private patchZones(): void {
    if (this.day && this.zoneComponents.get(0)) {
      this.zoneComponents.get(0).zips = [
        ...this.schedule[this.day + 'Zone'].amZips,
      ];
    } else {
      for (let i = 0; i < this.zoneComponents?.length; i++) {
        if (this.zoneComponents.get(i) && this.schedule[this.days[i] + 'Zone'])
        this.zoneComponents.get(i).zips = [
          ...this.schedule[this.days[i] + 'Zone'].amZips,
        ];
      }
    }
  }

  get hours() :{ value: string, viewValue: string }[] {
    return this.config.halfHourIntervals ? halfHours : hours;
  }
}
