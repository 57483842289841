import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'rspl-date-picker',
  templateUrl: './date-picker.component.html',
})
export class DatePickerComponent {
  @HostBinding('style.display') display = 'flex';
  @Input() title?: string;
  @Input() dateToEdit?: string;
  @Output() formattedDate = new EventEmitter();
  @Input() disabled = false;

  public onDateSelected(e: {value: string}): void {
    this.formattedDate.emit(moment(e.value).format('YYYY-MM-DD'));
  }

}
