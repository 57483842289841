import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import {
  getCharityScreening,
  selectCharityScreening,
  updateCharity,
} from '@core';
import {
  AppType,
  Charity,
  ENVIRONMENT,
  Environment,
  Screening,
  SplitScreen,
  SplitScreenVersion,
  WindowSize,
} from '@domains';
import { Store, select } from '@ngrx/store';
import { UploadFileService, UsersService } from '@rspl-api';
import { Responsive, ResponsiveService } from '@rspl-ui';
import { of } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-split-screen',
  templateUrl: './split-screen.component.html',
  styleUrls: ['./split-screen.component.scss'],
})
export class SplitScreenComponent extends Responsive implements OnInit {
  #charity: Charity;
  showLogo = false;
  splitScreenVersions = SplitScreenVersion;
  app: AppType;
  versions = [
    { value: SplitScreenVersion.V1, text: 'Design v1' },
    { value: SplitScreenVersion.V2, text: 'Design v2' },
    { value: SplitScreenVersion.V3A, text: 'Design v3A' },
    { value: SplitScreenVersion.V3B, text: 'Design v3B' },
  ];
  @Input() set charity(charity: Charity) {
    this.#charity = charity;
    if (this.charity) {
      this.getScreening();
    }
    if (this.charity && this.form) {
      this.setData();
    }
  }

  get charity(): Charity {
    return this.#charity;
  }

  config?: SplitScreen;
  preview: 'mobile' | 'desktop' = 'desktop';
  public form: FormGroup<{
    enabled: FormControl<boolean>;
    version: FormControl<SplitScreenVersion>;
    text: FormControl<string>;
    title: FormControl<string>;
    button: FormControl<string>;
    url: FormControl<string>;
    logoBackgroundColor: FormControl<string>;
    backgroundColor: FormControl<string>;
    textColor: FormControl<string>;
    btnBackgroundColor: FormControl<string>;
    btnTextColor: FormControl<string>;
    titleBackgroundColor: FormControl<string>;
    titleTextColor: FormControl<string>;
    stripeBackgroundColor: FormControl<string>;
    bulletColor: FormControl<string>;
    primaryReady: FormControl<boolean>;

    standardEnabled: FormControl<boolean>;
    textPrimary: FormControl<string>;
    titlePrimary: FormControl<string>;
    buttonPrimary: FormControl<string>;
    logoBackgroundColorPrimary: FormControl<string>;
    backgroundColorPrimary: FormControl<string>;
    textColorPrimary: FormControl<string>;
    btnBackgroundColorPrimary: FormControl<string>;
    btnTextColorPrimary: FormControl<string>;
    titleBackgroundColorPrimary: FormControl<string>;
    titleTextColorPrimary: FormControl<string>;
    bulletColorPrimary: FormControl<string>;
    showRestricedItems: FormControl<boolean>;
    restricedItemsUrl: FormControl<string>;
    restricedItemsText: FormControl<string>;
    standardReady: FormControl<boolean>;

    logo: FormControl<string>;
    logoOffsetTop: FormControl<number>;
    logoOffsetLeft: FormControl<number>;
    logoSize: FormControl<number>;
    logoOffsetTopMobile: FormControl<number>;
    logoOffsetLeftMobile: FormControl<number>;
    logoSizeMobile: FormControl<number>;
    logoStandardOffsetTop: FormControl<number>;
    logoStandardOffsetLeft: FormControl<number>;
    logoStandardSize: FormControl<number>;
    logoStandardOffsetTopMobile: FormControl<number>;
    logoStandardOffsetLeftMobile: FormControl<number>;
    logoStandardSizeMobile: FormControl<number>;

    reverse: FormControl<boolean>;
    discounts: FormControl<boolean>;
  }>;
  screening?: Screening;
  fullscreen = false;
  isCaptain: boolean;
  #logoTabs?: MatTabGroup;
  @ViewChild('logoTabs') set logoTabs(logoTabs: MatTabGroup) {
    this.#logoTabs = logoTabs;
    if (this.logoTabs && this.logoTabs.selectedIndex !== 0) {
      this.logoTabs.selectedIndex = this.preview === 'mobile' ? 2 : 1;
    }
  }

  get logoTabs(): MatTabGroup {
    return this.#logoTabs;
  }

  constructor(
    private store: Store,
    public override responsiveService: ResponsiveService,
    private userService: UsersService,
    private uploadFileService: UploadFileService,
    private snackBar: MatSnackBar,
    @Inject(ENVIRONMENT) private environment: Environment
  ) {
    super(responsiveService);
    this.isCaptain = environment.app === AppType.CAPTAIN;
    this.app = this.environment.app;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.initForm();
    this.responsiveService.size
      .pipe(takeUntil(this.destroy$))
      .subscribe((size) => {
        if ([WindowSize.XS, WindowSize.SM].includes(size)) {
          this.preview = 'mobile';
        }
      });
    this.store
      .pipe(select(selectCharityScreening), takeUntil(this.destroy$))
      .subscribe((screening) => {
        this.screening = screening;
      });
  }

  getScreening() {
    this.store.dispatch(getCharityScreening({ charityId: this.charity.id }));
  }

  private initForm(): void {
    this.form = new FormGroup({
      enabled: new FormControl(false),
      version: new FormControl<SplitScreenVersion>(SplitScreenVersion.V2),
      title: new FormControl(''),
      text: new FormControl(''),
      button: new FormControl(''),
      url: new FormControl('', [Validators.required]),
      backgroundColor: new FormControl('', [Validators.required]),
      textColor: new FormControl('', [Validators.required]),
      btnBackgroundColor: new FormControl('', [Validators.required]),
      btnTextColor: new FormControl('', [Validators.required]),
      titleBackgroundColor: new FormControl('', [Validators.required]),
      titleTextColor: new FormControl('', [Validators.required]),
      logoBackgroundColor: new FormControl('', [Validators.required]),
      stripeBackgroundColor: new FormControl('', [Validators.required]),
      bulletColor: new FormControl('', [Validators.required]),
      primaryReady: new FormControl(false),

      standardEnabled: new FormControl(false),
      showRestricedItems: new FormControl(false),
      restricedItemsUrl: new FormControl(''),
      restricedItemsText: new FormControl(''),
      titlePrimary: new FormControl(''),
      textPrimary: new FormControl(''),
      buttonPrimary: new FormControl(''),
      backgroundColorPrimary: new FormControl('', [Validators.required]),
      textColorPrimary: new FormControl('', [Validators.required]),
      btnBackgroundColorPrimary: new FormControl('', [Validators.required]),
      btnTextColorPrimary: new FormControl('', [Validators.required]),
      titleBackgroundColorPrimary: new FormControl('', [Validators.required]),
      titleTextColorPrimary: new FormControl('', [Validators.required]),
      logoBackgroundColorPrimary: new FormControl('', [Validators.required]),
      bulletColorPrimary: new FormControl('', [Validators.required]),
      standardReady: new FormControl(false),

      reverse: new FormControl(false),
      discounts: new FormControl(false),

      logo: new FormControl(''),
      logoOffsetTop: new FormControl(0),
      logoOffsetLeft: new FormControl(0),
      logoSize: new FormControl(0),
      logoOffsetTopMobile: new FormControl(0),
      logoOffsetLeftMobile: new FormControl(0),
      logoSizeMobile: new FormControl(0),
      logoStandardOffsetTop: new FormControl(0),
      logoStandardOffsetLeft: new FormControl(0),
      logoStandardSize: new FormControl(0),
      logoStandardOffsetTopMobile: new FormControl(0),
      logoStandardOffsetLeftMobile: new FormControl(0),
      logoStandardSizeMobile: new FormControl(0),
    });
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((changes) => {
        this.config = {
          ...this.config,
          ...this.form.getRawValue(),
        };
      });
    this.setData();
  }

  get logoOffsetTop(): number {
    return this.form?.get('logoOffsetTop')?.value || 0;
  }

  get logoOffsetLeft(): number {
    return this.form?.get('logoOffsetLeft')?.value || 0;
  }

  get logoSize(): number {
    return this.form?.get('logoSize')?.value || 0;
  }

  get logoOffsetTopMobile(): number {
    return this.form?.get('logoOffsetTopMobile')?.value || 0;
  }

  get logoOffsetLeftMobile(): number {
    return this.form?.get('logoOffsetLeftMobile')?.value || 0;
  }

  get logoSizeMobile(): number {
    return this.form?.get('logoSizeMobile')?.value || 0;
  }

  get logoStandardOffsetTop(): number {
    return this.form?.get('logoStandardOffsetTop')?.value || 0;
  }

  get logoStandardOffsetLeft(): number {
    return this.form?.get('logoStandardOffsetLeft')?.value || 0;
  }

  get logoStandardSize(): number {
    return this.form?.get('logoStandardSize')?.value || 0;
  }

  get logoStandardOffsetTopMobile(): number {
    return this.form?.get('logoStandardOffsetTopMobile')?.value || 0;
  }

  get logoStandardOffsetLeftMobile(): number {
    return this.form?.get('logoStandardOffsetLeftMobile')?.value || 0;
  }

  get logoStandardSizeMobile(): number {
    return this.form?.get('logoStandardSizeMobile')?.value || 0;
  }

  getUploadedFileUrl(event): void {
    this.form.patchValue({
      logo: event,
      logoOffsetTop: 0,
      logoOffsetLeft: 0,
      logoSize: 0,
      logoOffsetTopMobile: 0,
      logoOffsetLeftMobile: 0,
      logoSizeMobile: 0,
      logoStandardOffsetTop: 0,
      logoStandardOffsetLeft: 0,
      logoStandardSize: 0,
      logoStandardOffsetTopMobile: 0,
      logoStandardOffsetLeftMobile: 0,
      logoStandardSizeMobile: 0,
    });
  }

  onEnabledChange(enabled: boolean): void {
    this.form.get('enabled').setValue(enabled);
    if (enabled) {
      this.form
        .get('backgroundColorPrimary')
        .setValidators([Validators.required]);
      this.form.get('textColorPrimary').setValidators([Validators.required]);
      this.form
        .get('btnBackgroundColorPrimary')
        .setValidators([Validators.required]);
      this.form.get('btnTextColorPrimary').setValidators([Validators.required]);
      this.form
        .get('titleBackgroundColorPrimary')
        .setValidators([Validators.required]);
      this.form
        .get('titleTextColorPrimary')
        .setValidators([Validators.required]);
      this.form.get('logoBackgroundColor').setValidators([Validators.required]);
      this.form
        .get('stripeBackgroundColor')
        .setValidators([Validators.required]);
      this.form.get('bulletColor').setValidators([Validators.required]);
      if (this.standardEnabled) {
        this.form.get('url').setValidators([Validators.required]);
        this.form.get('backgroundColor').setValidators([Validators.required]);
        this.form.get('textColor').setValidators([Validators.required]);
        this.form
          .get('btnBackgroundColor')
          .setValidators([Validators.required]);
        this.form.get('btnTextColor').setValidators([Validators.required]);
        this.form
          .get('titleBackgroundColor')
          .setValidators([Validators.required]);
        this.form.get('titleTextColor').setValidators([Validators.required]);
      }
      this.form.enable();
      this.onShowRestricedItemsChange(this.showRestricedItems);
    } else {
      this.form.get('backgroundColorPrimary').clearValidators();
      this.form.get('textColorPrimary').clearValidators();
      this.form.get('btnBackgroundColorPrimary').clearValidators();
      this.form.get('btnTextColorPrimary').clearValidators();
      this.form.get('titleBackgroundColorPrimary').clearValidators();
      this.form.get('titleTextColorPrimary').clearValidators();
      this.form.get('logoBackgroundColor').clearValidators();
      this.form.get('stripeBackgroundColor').clearValidators();
      this.form.get('bulletColor').clearValidators();

      this.form.get('url').clearValidators();
      this.form.get('backgroundColor').clearValidators();
      this.form.get('textColor').clearValidators();
      this.form.get('btnBackgroundColor').clearValidators();
      this.form.get('btnTextColor').clearValidators();
      this.form.get('titleBackgroundColor').clearValidators();
      this.form.get('titleTextColor').clearValidators();
      this.form.disable();
    }
  }

  onStandardEnabledChange(enabled: boolean): void {
    this.form.get('standardEnabled').setValue(enabled);
    if (enabled) {
      this.form.get('url').setValidators([Validators.required]);
      this.form.get('backgroundColor').setValidators([Validators.required]);
      this.form.get('textColor').setValidators([Validators.required]);
      this.form.get('btnBackgroundColor').setValidators([Validators.required]);
      this.form.get('btnTextColor').setValidators([Validators.required]);
      this.form.get('logoBackgroundColor').setValidators([Validators.required]);
      this.form.get('bulletColor').setValidators([Validators.required]);
      if (
        [SplitScreenVersion.V3A, SplitScreenVersion.V3B].includes(
          this.config.version
        ) &&
        enabled
      ) {
        this.form.get('text').setValidators(Validators.required);
      }

      this.form.get('url').enable();
      this.form.get('title').enable();
      this.form.get('text').enable();
      this.form.get('button').enable();
      this.form.get('logo').enable();
      this.form.get('backgroundColor').enable();
      this.form.get('textColor').enable();
      this.form.get('btnBackgroundColor').enable();
      this.form.get('btnTextColor').enable();
      this.form.get('titleBackgroundColor').enable();
      this.form.get('titleTextColor').enable();
      this.form.get('logoBackgroundColor').enable();
      this.form.get('bulletColor').enable();
    } else {
      this.form.get('url').clearValidators();
      this.form.get('backgroundColor').clearValidators();
      this.form.get('textColor').clearValidators();
      this.form.get('btnBackgroundColor').clearValidators();
      this.form.get('btnTextColor').clearValidators();
      this.form.get('logoBackgroundColor').clearValidators();
      this.form.get('bulletColor').clearValidators();
      this.form.get('text').clearValidators();

      this.form.get('url').disable();
      this.form.get('title').disable();
      this.form.get('text').disable();
      this.form.get('button').disable();
      this.form.get('logo').disable();
      this.form.get('backgroundColor').disable();
      this.form.get('textColor').disable();
      this.form.get('btnBackgroundColor').disable();
      this.form.get('btnTextColor').disable();
      this.form.get('titleBackgroundColor').disable();
      this.form.get('titleTextColor').disable();
      this.form.get('logoBackgroundColor').disable();
      this.form.get('bulletColor').disable();
    }
  }

  onShowRestricedItemsChange(enabled: boolean): void {
    this.form.get('showRestricedItems').setValue(enabled);
    if (enabled) {
      this.form.get('restricedItemsText').setValidators([Validators.required]);
      this.form.get('restricedItemsUrl').setValidators([Validators.required]);

      this.form.get('restricedItemsText').enable();
      this.form.get('restricedItemsUrl').enable();
    } else {
      this.form.get('restricedItemsText').clearValidators();
      this.form.get('restricedItemsUrl').clearValidators();

      this.form.get('restricedItemsText').disable();
      this.form.get('restricedItemsUrl').disable();
    }
  }

  onPrimaryReadyChange(primaryReady: boolean): void {
    this.form.get('primaryReady').setValue(primaryReady);
    if (this.primaryReady) {
      this.form.get('buttonPrimary').enable();
    } else {
      this.form.get('buttonPrimary').disable();
    }
  }

  onStandardReadyChange(standardReady: boolean): void {
    this.form.get('standardReady').setValue(standardReady);
    if (this.standardReady) {
      this.form.get('url').setValidators([Validators.required]);
      this.form.get('url').enable();
      this.form.get('button').enable();
    } else {
      this.form.get('url').clearValidators();
      this.form.get('url').disable();
      this.form.get('button').disable();
    }
    this.form.get('url').updateValueAndValidity();
  }

  onReverseChange(reverse: boolean): void {
    this.form.get('reverse').setValue(reverse);
  }

  onDiscountsChange(discounts: boolean): void {
    this.form.get('discounts').setValue(discounts);
  }

  onVersionChange(version: SplitScreenVersion): void {
    this.form.get('version').setValue(version);
    if (
      [SplitScreenVersion.V3A, SplitScreenVersion.V3B].includes(
        this.config.version
      ) &&
      this.config.standardEnabled
    ) {
      this.form.get('text').setValidators(Validators.required);
    } else {
      this.form.get('text').clearValidators();
    }
  }

  get enabled(): boolean {
    return this.form.get('enabled')?.value;
  }

  get version(): SplitScreenVersion {
    return this.form.get('version')?.value;
  }

  get primaryReady(): boolean {
    return this.form.get('primaryReady')?.value;
  }

  get standardReady(): boolean {
    return this.form.get('standardReady')?.value;
  }

  get reverse(): boolean {
    return this.form.get('reverse')?.value;
  }

  get discounts(): boolean {
    return this.form.get('discounts')?.value;
  }

  get standardEnabled(): boolean {
    return this.form.get('standardEnabled')?.value;
  }

  get showRestricedItems(): boolean {
    return this.form.get('showRestricedItems')?.value;
  }

  get textValues(): string[] {
    return this.config.text?.trim().length > 0
      ? this.config.text?.trim().split('\n') || []
      : [];
  }

  get textValue(): string {
    return this.config.text?.trim().length > 0
      ? this.config.text?.trim().replaceAll('\n', '<br/>')
      : undefined;
  }

  get textPrimaryValues(): string[] {
    return this.config.textPrimary?.split('\n') || [];
  }

  get textPrimaryValue(): string {
    return this.config.textPrimary?.replaceAll('\n', '<br/>') || undefined;
  }

  submitForm(): void {
    if (!this.form.invalid) {
      const file: File = new File(
        [new Blob([JSON.stringify(this.config)])],
        `charity-${this.charity.id}-split-screen-config.json`
      );
      this.uploadFileService
        .uploadFile(file, true)
        .pipe(take(1))
        .subscribe((res) => {
          this.snackBar.open(
            'Split Landing Page configuration updated successfully!',
            null,
            {
              duration: 5000,
              panelClass: 'success',
            }
          );
          if (!this.charity.meta.splitScreenConfig) {
            this.charity = new Charity({
              ...this.charity,
              meta: { ...this.charity.meta, splitScreenConfig: res.url },
            });
            this.store.dispatch(updateCharity({ charity: this.charity }));
          }
        });
    } else {
      this.form.updateValueAndValidity();
      this.form.markAllAsTouched();
      this.snackBar.open(
        [SplitScreenVersion.V1, SplitScreenVersion.V2].includes(
          this.config.version
        )
          ? 'There are some validation errors! Please check all Split Landing Page configuration tabs and try again!'
          : 'There are some validation errors!',
        null,
        {
          duration: 5000,
          panelClass: 'error',
        }
      );
    }
  }

  get isDesktop(): boolean {
    return this.preview === 'desktop';
  }

  textChanged(): void {
    if (!(this.form.get('text').value?.trim()?.length > 0)) {
      this.form
        .get('text')
        .setValue(SplitScreen.DEFAULT[this.version].INITIAL_TEXT);
    }
  }

  restrictedTextChanged(): void {
    if (!(this.form.get('restricedItemsText').value?.trim()?.length > 0)) {
      this.form
        .get('restricedItemsText')
        .setValue(
          SplitScreen.DEFAULT[this.version].INITIAL_RESTRICTED_ITEMS_TEXT
        );
    }
  }

  textPrimaryChanged(): void {
    if (!(this.form.get('textPrimary').value?.trim()?.length > 0)) {
      this.form
        .get('textPrimary')
        .setValue(SplitScreen.DEFAULT[this.version].INITIAL_TEXT_PRIMARY);
    }
  }

  titleChanged(): void {
    if (!(this.form.get('title').value?.trim()?.length > 0)) {
      this.form
        .get('title')
        .setValue(SplitScreen.DEFAULT[this.version].INITIAL_TITLE_TEXT);
    }
  }

  btnTextChanged(): void {
    if (!(this.form.get('button').value?.trim()?.length > 0)) {
      this.form
        .get('button')
        .setValue(SplitScreen.DEFAULT[this.version].INITIAL_BTN_TEXT);
    }
  }

  btnTextPrimaryChanged(): void {
    if (!(this.form.get('buttonPrimary').value?.trim()?.length > 0)) {
      this.form
        .get('buttonPrimary')
        .setValue(SplitScreen.DEFAULT[this.version].INITIAL_BTN_TEXT_PRIMARY);
    }
  }

  titlePrimaryChanged(): void {
    if (!(this.form.get('titlePrimary').value?.trim()?.length > 0)) {
      this.form
        .get('titlePrimary')
        .setValue(SplitScreen.DEFAULT[this.version].INITIAL_TITLE_TEXT_PRIMARY);
    }
  }

  backgroundColorChanged(): void {
    if (!(this.form.get('backgroundColor').value?.trim()?.length > 0)) {
      this.form
        .get('backgroundColor')
        .setValue(SplitScreen.DEFAULT[this.version].INITIAL_BACKGROUND_COLOR);
    }
  }

  backgroundColorPrimaryChanged(): void {
    if (!(this.form.get('backgroundColorPrimary').value?.trim()?.length > 0)) {
      this.form
        .get('backgroundColorPrimary')
        .setValue(
          SplitScreen.DEFAULT[this.version].INITIAL_BACKGROUND_COLOR_PRIMARY
        );
    }
  }

  textColorChanged(): void {
    if (!(this.form.get('textColor').value?.trim()?.length > 0)) {
      this.form
        .get('textColor')
        .setValue(SplitScreen.DEFAULT[this.version].INITIAL_TEXT_COLOR);
    }
  }

  textColorPrimaryChanged(): void {
    if (!(this.form.get('textColorPrimary').value?.trim()?.length > 0)) {
      this.form
        .get('textColorPrimary')
        .setValue(SplitScreen.DEFAULT[this.version].INITIAL_TEXT_COLOR_PRIMARY);
    }
  }

  bulletColorPrimaryChanged(): void {
    if (!(this.form.get('bulletColorPrimary').value?.trim()?.length > 0)) {
      this.form
        .get('bulletColorPrimary')
        .setValue(
          SplitScreen.DEFAULT[this.version].INITIAL_BULLET_COLOR_PRIMARY
        );
    }
  }

  bulletColorChanged(): void {
    if (!(this.form.get('bulletColor').value?.trim()?.length > 0)) {
      this.form
        .get('bulletColor')
        .setValue(SplitScreen.DEFAULT[this.version].INITIAL_BULLET_COLOR);
    }
  }

  titleBackgroundColorChanged(): void {
    if (!(this.form.get('titleBackgroundColor').value?.trim()?.length > 0)) {
      this.form
        .get('titleBackgroundColor')
        .setValue(
          SplitScreen.DEFAULT[this.version].INITIAL_TITLE_BACKGROUND_COLOR
        );
    }
  }

  titleBackgroundColorPrimaryChanged(): void {
    if (
      !(this.form.get('titleBackgroundColorPrimary').value?.trim()?.length > 0)
    ) {
      this.form
        .get('titleBackgroundColorPrimary')
        .setValue(
          SplitScreen.DEFAULT[this.version]
            .INITIAL_TITLE_BACKGROUND_COLOR_PRIMARY
        );
    }
  }

  stripeBackgroundColorChanged(): void {
    if (!(this.form.get('stripeBackgroundColor').value?.trim()?.length > 0)) {
      this.form
        .get('stripeBackgroundColor')
        .setValue(
          SplitScreen.DEFAULT[this.version].INITIAL_STRIPE_BACKGROUND_COLOR
        );
    }
  }

  logoBackgroundColorPrimaryChanged(): void {
    if (
      !(this.form.get('logoBackgroundColorPrimary').value?.trim()?.length > 0)
    ) {
      this.form
        .get('logoBackgroundColorPrimary')
        .setValue(
          SplitScreen.DEFAULT[this.version]
            .INITIAL_LOGO_BACKGROUND_COLOR_PRIMARY
        );
    }
  }

  logoBackgroundColorChanged(): void {
    if (!(this.form.get('logoBackgroundColor').value?.trim()?.length > 0)) {
      this.form
        .get('logoBackgroundColor')
        .setValue(
          SplitScreen.DEFAULT[this.version].INITIAL_LOGO_BACKGROUND_COLOR
        );
    }
  }

  btnBackgroundColorPrimaryChanged(): void {
    if (
      !(this.form.get('btnBackgroundColorPrimary').value?.trim()?.length > 0)
    ) {
      this.form
        .get('btnBackgroundColorPrimary')
        .setValue(
          SplitScreen.DEFAULT[this.version].INITIAL_BTN_BACKGROUND_COLOR_PRIMARY
        );
    }
  }

  btnBackgroundColorChanged(): void {
    if (!(this.form.get('btnBackgroundColor').value?.trim()?.length > 0)) {
      this.form
        .get('btnBackgroundColor')
        .setValue(
          SplitScreen.DEFAULT[this.version].INITIAL_BTN_BACKGROUND_COLOR
        );
    }
  }

  titleTextColorChanged(): void {
    if (!(this.form.get('titleTextColor').value?.trim()?.length > 0)) {
      this.form
        .get('titleTextColor')
        .setValue(SplitScreen.DEFAULT[this.version].INITIAL_TITLE_TEXT_COLOR);
    }
  }

  titleTextColorPrimaryChanged(): void {
    if (!(this.form.get('titleTextColorPrimary').value?.trim()?.length > 0)) {
      this.form
        .get('titleTextColorPrimary')
        .setValue(
          SplitScreen.DEFAULT[this.version].INITIAL_TITLE_TEXT_COLOR_PRIMARY
        );
    }
  }

  btnTextColorChanged(): void {
    if (!(this.form.get('btnTextColor').value?.trim()?.length > 0)) {
      this.form
        .get('btnTextColor')
        .setValue(SplitScreen.DEFAULT[this.version].INITIAL_BTN_TEXT_COLOR);
    }
  }

  btnTextColorPrimaryChanged(): void {
    if (!(this.form.get('btnTextColorPrimary').value?.trim()?.length > 0)) {
      this.form
        .get('btnTextColorPrimary')
        .setValue(
          SplitScreen.DEFAULT[this.version].INITIAL_BTN_TEXT_COLOR_PRIMARY
        );
    }
  }

  get backgroundColor(): FormControl<string> {
    return this.form.get('backgroundColor') as FormControl<string>;
  }

  get textColor(): FormControl<string> {
    return this.form.get('textColor') as FormControl<string>;
  }

  get textColorPrimary(): FormControl<string> {
    return this.form.get('textColorPrimary') as FormControl<string>;
  }

  get bulletColorPrimary(): FormControl<string> {
    return this.form.get('bulletColorPrimary') as FormControl<string>;
  }

  get bulletColor(): FormControl<string> {
    return this.form.get('bulletColor') as FormControl<string>;
  }

  get backgroundColorPrimary(): FormControl<string> {
    return this.form.get('backgroundColorPrimary') as FormControl<string>;
  }

  get btnBackgroundColor(): FormControl<string> {
    return this.form.get('btnBackgroundColor') as FormControl<string>;
  }

  get btnBackgroundColorPrimary(): FormControl<string> {
    return this.form.get('btnBackgroundColorPrimary') as FormControl<string>;
  }

  get titleBackgroundColor(): FormControl<string> {
    return this.form.get('titleBackgroundColor') as FormControl<string>;
  }

  get titleBackgroundColorPrimary(): FormControl<string> {
    return this.form.get('titleBackgroundColorPrimary') as FormControl<string>;
  }

  get stripeBackgroundColor(): FormControl<string> {
    return this.form.get('stripeBackgroundColor') as FormControl<string>;
  }

  get logoBackgroundColorPrimary(): FormControl<string> {
    return this.form.get('logoBackgroundColorPrimary') as FormControl<string>;
  }

  get logoBackgroundColor(): FormControl<string> {
    return this.form.get('logoBackgroundColor') as FormControl<string>;
  }

  get btnTextColor(): FormControl<string> {
    return this.form.get('btnTextColor') as FormControl<string>;
  }

  get btnTextColorPrimary(): FormControl<string> {
    return this.form.get('btnTextColorPrimary') as FormControl<string>;
  }

  get titleTextColor(): FormControl<string> {
    return this.form.get('titleTextColor') as FormControl<string>;
  }

  get titleTextColorPrimary(): FormControl<string> {
    return this.form.get('titleTextColorPrimary') as FormControl<string>;
  }

  toggleFullScreen(): void {
    this.fullscreen = !this.fullscreen;
    const body = document.body;
    if (this.fullscreen) {
      body.classList.add('fullscreen');
    } else {
      body.classList.remove('fullscreen');
    }
  }

  selectedTab(textLabel: string): void {
    switch (textLabel) {
      case 'Logo':
        this.showLogo = true;
        break;
    }
  }

  private setData(): void {
    (this.charity.meta.splitScreenConfig
      ? this.userService.getConfig(this.charity.meta.splitScreenConfig)
      : of(this.charity.meta)
    )
      .pipe(take(1))
      .subscribe((data) => {
        this.config = new SplitScreen(data);
        this.form.patchValue(this.config);
        this.onStandardReadyChange(this.config.standardReady);
        this.onPrimaryReadyChange(this.config.primaryReady);
        this.onStandardEnabledChange(this.config.standardEnabled);
        this.onShowRestricedItemsChange(this.config.showRestricedItems);
        this.onEnabledChange(this.config.enabled);
      });
  }

  setPreview(preview: 'mobile' | 'desktop'): void {
    this.preview = preview;
    if (this.logoTabs && this.logoTabs.selectedIndex !== 0) {
      this.logoTabs.selectedIndex = preview === 'mobile' ? 2 : 1;
    }
  }

  logoTabChanged($event: MatTabChangeEvent): void {
    if ($event.tab.textLabel === 'Mobile Positioning') {
      this.preview = 'mobile';
    } else if ($event.tab.textLabel === 'Desktop Positioning') {
      this.preview = 'desktop';
    }
  }

  get pickUp(): boolean {
    return this.charity.meta.pickUp;
  }

  disableScroll() {
    document.body.style.overflow = 'hidden';
  }

  enableScroll() {
    document.body.style.overflow = 'initial';
  }
}
