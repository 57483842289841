import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AnomaliesService } from '@rspl-api';
import { EMPTY } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromActions from './anomaly.actions';

@Injectable()
export class AnomalyEffects {
  getAnomalies = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getAnomalies),
      switchMap((params) =>
        this.service.filter(params.findParams).pipe(
          map((results) => {
            return fromActions.getAnomaliesSuccess({
              anomalies: results.results,
              resultsCount: results.totalResults,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  constructor(private actions$: Actions, private service: AnomaliesService) {}
}
