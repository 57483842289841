import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColumnConfig } from '../table/table-config';

@Component({
  selector: 'rspl-column-config',
  templateUrl: './column-config.component.html',
  styleUrls: ['./column-config.component.scss']
})
export class ColumnConfigComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { config: { [key: string]: ColumnConfig }, columns: { field: string, index: number }[] },
    public reference: MatDialogRef<ColumnConfigComponent>,
  ) {
  }

  ngOnInit(): void {
  }
}
