import { AnomalyState } from './anomaly/anomaly.reducers';
import { StoresState } from './charity-store/charity-store.reducers';
import { CharityState } from './charity/charity.reducers';
import { DonationState } from './donation/donation.reducers';
import { ItemState } from './item/item.reducers';
import { JourneysState } from './journey/journey.reducers';
import { MarketsState } from './market/market.reducers';
import { NotificationsState } from './notification/notification.reducers';
import { OnboardingState } from './onboarding/onboarding.reducers';
import { OrganizationState } from './organization/organization.reducers';
import { PageState } from './page/page.reducers';
import { PartnerState } from './partner/partner.reducers';
import { PaymentsState } from './payment/payment.reducers';
import { PhoneConfigurationState } from './phone-configuration/phone-configuration.reducers';
import { ScheduleHistoryState } from './schedule-history/schedule-history.reducers';
import { TrpsState } from './trp/trp.reducers';
import { TrucksState } from './truck/truck.reducers';
import { UsersState } from './user/user.reducers';
import { XmileState } from './xmile/xmile.reducers';
import { ZoneState } from './zone/zone.reducers';

export interface AppState {
  donation: DonationState;
  charity: CharityState;
  store: StoresState;
  user: UsersState;
  truck: TrucksState;
  onboarding: OnboardingState;
  partner: PartnerState;
  item: ItemState;
  zone: ZoneState;
  payment: PaymentsState;
  page: PageState;
  market: MarketsState;
  xmile: XmileState;
  trp: TrpsState;
  scheduleHistory: ScheduleHistoryState;
  notification: NotificationsState;
  journey: JourneysState;
  organization: OrganizationState;
  phoneConfiguration: PhoneConfigurationState;
  anomaly: AnomalyState;
}
